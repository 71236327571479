import axios from 'axios';

// const backendUrl = 'http://localhost:21300' //DEV
// const backendUrl = '192.168.0.194:22310'
// const backendUrl = 'https://apps.delphus.inf.br:22311' //HOMOLOGA
let backendUrl = 'https://apps.delphus.inf.br:21301' //PROD

if (window.location.origin == 'http://localhost:3000'){
    backendUrl = 'http://localhost:21300'
} else if (window.location.origin == 'https://apps.delphus.inf.br'){
    backendUrl = 'https://apps.delphus.inf.br:22311'
}

const auth = (JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0 })))

const api = axios.create({
    baseURL: backendUrl + `/api/`
});

api.interceptors.request.use(function (config) {

    var token = ''
    if (auth) {
        token = auth.access_token

    }
    config.headers.token =  token;
     
    return config;
});

api.getComboData = async (table, parameter) => {
    return new Promise(async (resolve, reject) => {
        console.log('Searching for parameter ' + parameter)
        await api.get('/' + table + '/combo' + (parameter ? '/' + parameter : '')).then(async r => {
            console.log(r.data)
            resolve(r.data)
        })
    })
}

api.usuIsAdm = async (usu) => {
    return new Promise (async (resolve, reject) => {
        await api.get('/usuarios?pk_usu='+ usu).then(r =>{ 
            console.log(usu)
            resolve(r.data[0].administrador)
        })
    })
}
api.getAcessos = async (tela, opcao) => {
    return new Promise(async (resolve, reject) => {
        // console.log('Searching for parameter '+tela+' '+opcao)
        let auth = JSON.parse(localStorage['authDelphus']);

        await api.get('/grupo_usuarios/permissoes?tela=' + tela + '&pk_usu=' + auth.user + '&acao=' + opcao).then(async r => {
            if (opcao === 'Consulta') {
                try {
                    resolve(r.data[0].Consulta)
                }
                catch { resolve('N') }
            }

            if (opcao === 'Inclusao') {
                try {
                    resolve(r.data[0].Inclusao)
                }
                catch { resolve('N') }
            }

            if (opcao === 'Alteracao') {
                try {
                    resolve(r.data[0].Alteracao)
                }
                catch { resolve('N') }
            }

            if (opcao === 'Exclusao') {
                try {
                    resolve(r.data[0].Exclusao)
                }
                catch { resolve('N') }
            }

            // resolve(r.data)
        })
    })
}


api.getListData = async (table, parameter) => {
    return new Promise(async (resolve, reject) => {
        console.log('Searching for parameter ' + parameter)
        await api.get('/' + table + '/lista' + (parameter ? '/' + parameter : '')).then(async r => {
            console.log(r.data)
            resolve(r.data)
        })
    })
}

api.getImage = async (regName, pk, image) => {
    return new Promise(async (resolve, reject) => {
        if (pk && image) {
            console.log(backendUrl + '/static/upload/fotos/' + regName + '/' + pk + '/' + image)
            axios.get(backendUrl + '/static/upload/fotos/' + regName + '/' + pk + '/' + image).then(r => {
                resolve(backendUrl + '/static/upload/fotos/' + regName + '/' + pk + '/' + image)
            }).catch(e => resolve(backendUrl + '/static/upload/no-image.jpg'))
        } else resolve(backendUrl + '/static/upload/no-image.jpg')
    })
}

api.getTipoDocumento = async (fk_mdl) => {
    return new Promise(async (resolve, reject) => {
        resolve(await api.getComboData('tipo_documento', fk_mdl))
    })
}

api.noImageUrl = backendUrl + '/static/upload/no-image.jpg'
api.backendUrl = backendUrl

export default api