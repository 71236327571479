import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FrTextInput from '../FrTextInput';
import { Typography } from '@material-ui/core';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content/'
import api from '../../services/api'
import './index.css';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_description } from 'react-icons-kit/md/ic_description'
import { dataHoraSql } from '../../utils/functions'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import { ConsoleView } from 'react-device-detect';
import { search } from 'react-icons-kit/icomoon/search';
import { cross } from 'react-icons-kit/icomoon/cross';
import download from 'downloadjs';
import { download2 } from 'react-icons-kit/icomoon/download2';

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);




function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#45688d',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#45688d'
    },
    "& .Mui-selected:hover": {
      backgroundColor: '#45688d'
    },
    width: '100%',
  },
  container: {
    maxHeight: '45vh',
    minHeight: '45vh'
  },
  containerContatos: {
    maxHeight: '50vh',
    minHeight: '50vh'
  },
});


export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    console.log(property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage ? props.rowsPerPage : 10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState(props.order || 'desc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy || props.columns[0].id);
  const [width, setWidth] = useState(props.width ? props.width : 100);


  useEffect(() => {
    setData(props.data)
    setPage(0)

  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, name, campo) => {
    if (props.setCli) {
      console.log(name)
      props.setCli(name)
    } else if (props.setCliC) {
      console.log(name)
      props.setCliC(name)
    } else if (props.setMdc) {
      props.setMdc(name)
    } else {
      console.log(name, props, event, campo)
    }
  };

  // function handleLink(e, fk_pac, nomepac, datamov, pk_ni1) {
  //   e.preventDefault()
  //   if (props.regName == 'evolucao')
  //     window.location.href = "/mercado/mostraEvolucao?fk_pac=" + fk_pac + "&nomepac=" + nomepac + '&data=' + dataHoraSql(datamov) + '&fk_ni1=' + pk_ni1
  //   else
  //     window.location.href = "/mercado/detalhaEvolucao?pk_pac=" + fk_pac + "&nomepac=" + nomepac
  // }

  function handleEdit(e, row) {
    e.preventDefault()
    if (props.setCon_Tratamento) {
      props.setCon_Tratamento(row['tratamento'])
      props.setCon_Nome(row['nome'])
      props.setCon_Cargo(row['cargo'])
      props.setCon_Email(row['email'])
      props.setCon_Fone_1(row['fone_1'])
      props.setCon_Celular(row['celular'])
      props.setCon_Cpf(row['cpf'])
      props.setCon_Aniversario(row['aniversario'])
      if (row['aniversario']) {
        props.setCon_Dia(row['aniversario'].substring(0, 2))
        props.setCon_Mes(row['aniversario'].substring(3, 6))
      } else {
        props.setCon_Dia('')
        props.setCon_Mes('')
      }
      props.setCon_Observacoes(row['observacoes'])
      props.setCon_Mala_Direta(row['mala_direta'])
      props.setCon_Ct(row['ct'])
      props.setCon_Bbm(row['bbm'])
      props.setCon_Msn(row['msn'])

    }
    if (props.setfk_pro) {
      props.setfk_pro(row['fk_pro'])
      props.setficha_bbm(row['ficha_bbm'])
    }
    if (props.setMarca) {
      props.setMarca(row['descricao'])
    }

    if (props.setfk_tdo) {
      props.setfk_tdo(row['fk_tdo'])
      props.setdata_entrega(row['entrega'])
      props.setespecificacao(row['especificacao'])
      props.setArquivo(row['arquivo'])

    }


    if (props.setnf_data) {
      props.setnf_data(row['nf_data'])
      props.setnf_numero(row['nf_numero'])
      props.setnf_data_pgto(row['nf_data_pgto'])
      props.settipo_nota(row['tipo_nota'])
      props.setnf_valor_bruto(row['nf_valor_bruto'])
      props.setnf_valor_liquido(row['nf_valor_liquido'])
    }

    if (props.setLote) {
      props.setLote(row['lote'])
      props.setfk_cli(row['fk_cli'])
      props.setQuantidade(row['quantidade'])
      props.setValor(row['valor'])
      props.setCnpjcli(row['cnpjcli'])
      props.setInestcli(row['inestcli'])

    }

    if(props.setCon_servidor){
      console.log(row)
      props.setCon_servidor(row['servidor'])
      props.setCon_software(row['software'])
      props.setCon_ip(row['ip'])
      props.setCon_senha(row['senha'])
      props.setCon_usuario(row['usuario'])
      props.setCon_tipo_conexao(row['tipo_conexao'])
      props.setCon_observacoes(row['observacoes'])

    }



    props.setPkModal(row['pk'])
    props.setUpdateModal(true)
    props.setModal(true)

  }

  function handleDelete(e, name, pk) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if ((pk > 0) && (props.functionDelete)) {
          api.post(props.functionDelete + pk).then(r => {
            // console.log(r.data)
            if ((r.status === 200) && (r.data.substr(0, 5) == 'Erro!')) {
              swal.fire({
                title: 'Erro!',
                text: "O registro não pode ser excluído! " + r.data.substr(6, r.data.length),
                icon: 'warning',
              })
            } else
              console.log(pk)
              deletaItem(e, name, pk)
          })
        } else{
          console.log(pk) 
          deletaItem(e, name, pk)
        }
      }
    })
  }

  function deletaItem(e, name, pk) {
    var sliced = [];
    var excluidos = []
    let reg = []
    console.log(props.regName)
    if(props.regName == 'frTableEmolumentos'){
      reg = data.filter(item => item.pk_cto != name)
      // sliced.push(data.filter(r=> {
      //   return r.name != name 
      // }))
      props.setDataGrid(reg)
      
    } else {
        for (var i = 0; i < data.length; i++)
        if (pk !== 0) {
          console.log('ta pk !== 0')
  
          if ((data[i].pk !== pk) && (data[i].pk !== 0)) {
            sliced.push(data[i])
            if ((pk > 0) && (props.arrayExcluidos)) {
              for (var x = 0; x < props.arrayExcluidos.length; x++) {
                excluidos.push(props.arrayExcluidos[x])
              }
              excluidos.push(pk)
              props.setArrayExcluidos(excluidos)
            }
          }
        } else {
          console.log('ta por aqui')
          if ((data[i].display !== name) && (data[i].display !== '')) {
            sliced.push({
              value: data[i].value,
              display: data[i].display
            })
          }
        }

        if (sliced.length === 0) {
          sliced.push({
            value: '',
            display: '',
            pk: 0
      })
    }

    if (props.setDataDoe) {
      props.setDataDoe(sliced)
    }
    
    if (props.setDataGrid) {
      props.setDataGrid(sliced)
    }
  }
  }

  const separaArquivosMov = (arquivos, i) => {
    
    // console.log(arquivos, i);
    // console.log(props.data[i])

    let cmo = props.data[i].pk_cmo;
    let cha = props.data[i].fk_cha;

    let arq = arquivos ? arquivos.split(',') : null;

    if(arq){      

      arq.map((item, index) => {

      // console.log(index, props.data[index])
      // console.log(arq[index])
        
      let pos = 31 + String(cmo).length + String(cha).length;

      // console.log(i, cmo, String(cmo).length , cha, String(cha).length)

      let nomeArq = item.substring(pos, 100)

      if (index == 0)
        props.data[i].arquivoMov1_1 = nomeArq
      if (index == 1)
        props.data[i].arquivoMov2_1 = nomeArq
      if (index == 2)
        props.data[i].arquivoMov3_1 = nomeArq
      if (index == 3)
        props.data[i].arquivoMov4_1 = nomeArq
      if (index == 4)
        props.data[i].arquivoMov5_1 = nomeArq
      if (index == 5)
        props.data[i].arquivoMov6_1 = nomeArq

      // console.log(props.data[i])
      // console.log(nomeArq)  
      })        
    }
  }

  const handleViewMov = (n, i) => {
    let arq = '';
    let cmo = props.data[i].pk_cmo;
    if (n == 1)
        arq = props.data[i].arquivoMov1_1;
    if (n == 2)
        arq = props.data[i].arquivoMov2_1;
    if (n == 3)
        arq = props.data[i].arquivoMov3_1;
    if (n == 4)
        arq = props.data[i].arquivoMov4_1;
    if (n == 5)
        arq = props.data[i].arquivoMov5_1;
    if (n == 6)
        arq = props.data[i].arquivoMov6_1;

    // console.log(arq, props.data[i].pk_cmo)

    api.post(`/chamado_mov/visualizarArquivo?arquivo=${arq}&fk_cmo=${cmo}&cha=${props.data[i].fk_cha}`, {}, {responseType: 'blob'}).then(r => {
        console.log(r);
        var blob = new Blob([r.data], {
            type: r.data.type
        });
        // console.log(blob)
        // $("#img1").attr("src", window.URL.createObjectURL(blob));
        window.open(window.URL.createObjectURL(blob))
    });
  
  }

  const handleDownloadMov = (n, i) => {
    let arq;
    let cmo = props.data[i].pk_cmo;
    console.log(arq, cmo);
  
    if (n == 1)
        arq = props.data[i].arquivoMov1_1;
    if (n == 2)
        arq = props.data[i].arquivoMov2_1;
    if (n == 3)
        arq = props.data[i].arquivoMov3_1;
    if (n == 4)
        arq = props.data[i].arquivoMov4_1;
    if (n == 5)
        arq = props.data[i].arquivoMov5_1;
    if (n == 6)
        arq = props.data[i].arquivoMov6_1;

    api.post(`chamado_mov/downloadAnexo?arquivo=${arq}&fk_cmo=${cmo}&cha=${props.data[i].fk_cha}`, {}, {responseType: 'blob'}).then((response) => {
      if(response.data.type == 'application/json'){
        swal.fire({
            title: 'Arquivo Não Encontrado',
            text: "Arquivo não foi encontrado no servidor!",
            icon: 'error',
        })
      } else {
        console.log(response, props.data[i].arquivo)
          var blob = new Blob([response.data], {
            type: response.data.type
          });
          download(blob, arq)
      }
    })
  }

  const quebraLinha = (texto) => {
    //console.log(texto);
    let linhas = texto.split('\n');    
    //console.log(linhas);
    let deets = [];
    linhas.map(linha => {
      //console.log(linha);
        deets.push(<><>{linha}</> <br/></>);
      });
    return deets;
  }

  return (
    <ThemeProvider theme={theme}>
      {(data.length === 0) ? (
        <div>{props.searched ? props.emptyMessage : ''}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={props.regName == 'contatos' ? classes.containerContatos : classes.container}>
            {/* <Table stickyHeader className={classes.table} aria-label="sticky table"> */}
            <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">

              <TableHead>
                <StyledTableRow>
                  {(!props.readonly) ?
                    <StyledTableCell
                      style={{ minWidth: width, width: width }}
                    >

                    </StyledTableCell>
                    : <></>}
                  {columns.map((column) => (
                    !column.hidden ?
                      props.sortable ?
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={(column.id != 'cnpj' && column.id != 'insc_estadual') ? createSortHandler(column.id) : ''}
                          >
                            {column.label}
                          </TableSortLabel>
                        </StyledTableCell>

                        :

                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {/* <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)} */}
                          {/* > */}
                          {column.label}
                          {/* </TableSortLabel> */}
                        </StyledTableCell>
                    :
                    <></>
                  ))}

                  {/* <StyledTableCell style={{ minWidth: 30, width: 30 }} /> */}

                </StyledTableRow>

              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  // console.log(row, index)
                  //{data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  // console.log(row, index)
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow onClick={(event) => handleClick(event, row[props.codeName], props.codeName)} hover tabIndex={-1} key={row[props.codeName]}>
                      {(!props.readonly) && ((row[props.codeName] !== '') || (row['pk'] > 0)) ?
                        <TableCell>
                          <Icon className="tableDelete" icon={ic_delete} size={15} onClick={(e) => handleDelete(e, row[props.codeName], row['pk'])} />
                          {(props.editar) ?
                            <Icon className="tableDelete" icon={ic_mode_edit} size={15} onClick={(e) => handleEdit(e, row)} />
                            : <></>}
                        </TableCell> : <></>}

                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.icon) {
                          return (
                            // <TableCell key={column.id} align={column.align}>

                            //   <Icon className={row['alerta'] !== 'S' ? "tableOk" : "tableOkRed"} icon={ic_description} size={22} onClick={(e) => handleLink(e, row['fk_pac'], row['nomepac'], row['auxdatamov'], row['pk_ni1'])} />

                            // </TableCell>
                            <>æ</>
                          )
                        } else if(column.id == 'descricao'){
                          return (
                            !column.hidden ?
                            <TableCell key={column.id} align={column.align}>
                              {quebraLinha(row['descricao'])}
                            </TableCell>
                            :
                            <></>
                          );
                        } else if(column.id == 'temArq'){
                          return(
                            <TableCell hidden={column.hidden} key={column.id} align={column.align}>                                    
                              {separaArquivosMov(row['arquivos'], index)}

                              <FrTextInput
                                value={props.data[index].arquivoMov1_1 || ''}
                                name="arquivoMov1_1"
                                id="arquivoMov1_1"
                                color="#49573B"
                                variant="outlined"
                                size="small"
                                disabled
                                style={{ width: 300 }}
                                hidden={!props.data[index].arquivoMov1_1}
                                startAdornment={
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                            <Icon icon={search} size={14} onClick={(e) => { handleViewMov(1, index); }} />
                                        </div>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                            <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(1, index); }} />
                                        </div>
                                    </div>

                                }
                                // endAdornment={
                                //     <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                //         <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(1, index); }} />
                                //     </div>
                                // }
                              />

                              <FrTextInput
                                value={props.data[index].arquivoMov2_1 || ''}
                                name="arquivoMov2_1"
                                id="arquivoMov2_1"
                                color="#49573B"
                                variant="outlined"
                                size="small"
                                disabled
                                style={{ width: 300 }}
                                hidden={!props.data[index].arquivoMov2_1}
                                startAdornment={
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                            <Icon icon={search} size={14} onClick={(e) => { handleViewMov(2, index); }} />
                                        </div>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                            <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(2, index); }} />
                                        </div>
                                    </div>

                                }
                                // endAdornment={
                                //     <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                //         <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(2, index); }} />
                                //     </div>
                                // }
                              />

                              <FrTextInput
                                value={props.data[index].arquivoMov3_1 || ''}
                                name="arquivoMov3_1"
                                id="arquivoMov3_1"
                                color="#49573B"
                                variant="outlined"
                                size="small"
                                disabled
                                style={{ width: 300 }}
                                hidden={!props.data[index].arquivoMov3}
                                startAdornment={
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                            <Icon icon={search} size={14} onClick={(e) => { handleViewMov(3, index); }} />
                                        </div>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                            <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(3, index); }} />
                                        </div>
                                    </div>

                                }
                                // endAdornment={
                                //     <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                //         <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(3, index); }} />
                                //     </div>
                                // }
                              />

                              <FrTextInput
                                value={props.data[index].arquivoMov4_1 || ''}
                                name="arquivoMov4_1"
                                id="arquivoMov4_1"
                                color="#49573B"
                                variant="outlined"
                                size="small"
                                disabled
                                style={{ width: 300 }}
                                hidden={!props.data[index].arquivoMov4_1}
                                startAdornment={
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                            <Icon icon={search} size={14} onClick={(e) => { handleViewMov(4, index); }} />
                                        </div>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                            <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(4, index); }} />
                                        </div>
                                    </div>

                                }
                                // endAdornment={
                                //     <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                //         <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(4, index); }} />
                                //     </div>
                                // }
                              />

                              <FrTextInput
                                value={props.data[index].arquivoMov5_1 || ''}
                                name="arquivoMov5_1"
                                id="arquivoMov5_1"
                                color="#49573B"
                                variant="outlined"
                                size="small"
                                disabled
                                style={{ width: 300 }}
                                hidden={!props.data[index].arquivoMov5_1}
                                startAdornment={
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                            <Icon icon={search} size={14} onClick={(e) => { handleViewMov(5, index); }} />
                                        </div>
                                        <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                            <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(5, index); }} />
                                        </div>
                                    </div>

                                }
                                // endAdornment={
                                //     <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                //         <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(5, index); }} />
                                //     </div>
                                // }
                              />

                              <FrTextInput
                                value={props.data[index].arquivoMov6_1 || ''}
                                name="arquivoMov6_1"
                                id="arquivoMov6_1"
                                color="#49573B"
                                variant="outlined"
                                size="small"
                                disabled
                                style={{ width: 300 }}
                                hidden={!props.data[index].arquivoMov6_1}
                                startAdornment={                                                
                                    <div>
                                        <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                                            <Icon icon={search} size={14} onClick={(e) => { handleViewMov(6, index); }} />
                                        </div>
                                        <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                                            <Icon icon={search} size={14} onClick={(e) => { handleDownloadMov(6, index); }} />
                                        </div>
                                    </div>

                                }
                                // endAdornment={
                                //     <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                //         <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(6, index); }} />
                                //     </div>
                                // }
                              />
                            </TableCell>
                          )
                        } else {
                          return (
                            !column.hidden ?
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                            :
                            <></>
                          );
                        }
                      })}
                    </StyledTableRow>
                  );
                  //})}
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )
      }
    </ThemeProvider >
  );
}
