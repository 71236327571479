import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../services/api'

import './styles/Login.css';

import logo from '../imgs/delphus_full_white.png';

const swal = withReactContent(Swal)

function Login() {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0 })));

    useEffect(() => {
        if (auth.inativo == true){            
			swal.fire({
				title: 'Desconectado por inatividade',
				text: "Sessão expirada. Por favor, faça o login novamente.",
				icon: 'warning',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'Ok',
				allowOutsideClick: false
			})
            auth.inativo = false;
            localStorage['authDelphus'] = JSON.stringify(auth);
        }
    }, [])

    useEffect(() => {
        if (auth.authenticated) window.location.href = '/suporte/home'
    }, [auth])

    function login(e) {
        e.preventDefault();
        //Pega valores do form
        const form = document.getElementById('loginForm');
        const data = new FormData(form);

        if (data.get('login') === '') {
            swal.fire('Usuário não informado!', 'Informe o usuário.', 'warning')
            return
        }

        if (data.get('senha') === '') {
            swal.fire('Senha não informada!', 'Informe a senha.', 'warning')
            return
        }
        //Converte FormData em JSON
        var object = {};

        data.forEach(function (value, key) {
            object[key] = value;
        });

        //Checa no servidor
        api.post('usuarios/login', object)
            .then(function (response) {
                let { pk_usu, nome, ultimo_login, fk_gpu, fk_cli } = response.data[0];
                if (pk_usu && pk_usu > 0) {
                    localStorage['authDelphus'] = JSON.stringify({ authenticated: true, fk_gpu: fk_gpu, fk_cli: fk_cli, user: pk_usu, name: nome, ultimo_login, last_activity: new Date(), access_token: response.data.token})
                    window.location.href = '/suporte/home'

                } else {
                    swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
                }
            })
            .catch(function (error) {
                swal.fire('Login inválido!', 'Verifique o Usuário e a Senha.', 'warning')
            });
    }

    return (
        <div className="Login">
            <div className="form">
                <form className="form-signin" id="loginForm" onSubmit={login}>
                    <img alt="logo" src={logo} title="delphus" className="logo" />
                    <div className="form-label-group">
                        <input type="text" id="login" name="login" className="loginInput" placeholder="Usuário" />
                        <label htmlFor="login">Login</label>
                    </div>
                    <div className="form-label-group">
                        <input type="password" id="senha" name="senha" className="loginInput" placeholder="Senha" />
                        <label htmlFor="senha">Senha</label>
                    </div>
                    <button className="loginButton" type="submit" rel="nofollow">
                        <span><p className='loginButtonText'>Entrar</p></span>
                    </button>
                    <div className="forgotPwDiv">
                        <a style={{ color: 'white' }} href='/suporte/forgotPw'>Esqueci minha senha</a>
                    </div>

                </form>
            </div>
            {/* <div className="delphus">
                <img alt="delphus" src={delphus} title="delphus" className="logo_delphus" />
            </div> */}
        </div>
    );
}

export default Login;
