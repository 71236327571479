import React, {useEffect, useState} from 'react';
import NavBar from '../components/NavBar';

const Cidade = () => {

    

    return(
        <>
            <NavBar />
        </>
    )

}

export default Cidade;