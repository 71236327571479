import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import CardActionArea from '@material-ui/core/CardActionArea';

import FrLoading from '../FrLoading'
import api from '../../services/api'
import FrTableReg from '../FrTableReg'
import './index.css';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 15,
    marginLeft: 0,
    minWidth: 345,
    '&:first-child': {
      marginLeft: 15,
    },
  }
})

function TableDetail(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = useStyles();
  const [auth, setAuth] = useState(JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0, fk_gpu: 0 })));

  const columns = [
    { id: 'data', hidden: true },
    { id: 'format_datetime', numeric: false, label: 'Data', align: 'left', minWidth: 100, orderBy: 'data' },
    { id: 'nomeusu', numeric: false, label: 'Usuário', align: 'left', minWidth: 150 },
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 250 },
    { id: 'nometmo', numeric: false, label: 'Tipo de Movimentação', align: 'left', minWidth: 150 },
    { id: 'temArq', numeric: false, label: 'Arquivos', align: 'left', minWidth: 140}

  ]

  let history = useHistory();


  useEffect(() => {
    const fetchData = async () => {
      if (props.isOpen) {
        setLoading(true)
        let wherePublico = ''
        if (auth.fk_gpu > 2)
          wherePublico = '&publico=S'
        api.get('/chamado_mov?pk_cha=' + props.detail + wherePublico).then(r => {
          console.log(r.data)
          setData(r.data)
          setLoading(false)
        })
      }
    }

    fetchData()
  }, [props.isOpen])

  if (loading) {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3} >
              <FrLoading text="Buscando dados..." />
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  } else if (data.length > 0) {
    return (
      <>

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>

            <FrTableReg readonly={true} detail regName="" columns={columns} searched={false} codeName="display" page={''} data={data} order='desc' />

            {/* <Box margin={3} >
              <Typography variant="h6" gutterBottom component="div">
                Container
                    </Typography>
              <div className="DetailScrollWrapper">
                <div className="DetailOfetasList">
                  {data.map((item) => {
                    return (
                      <Card className={classes.root}>
                        <CardActionArea onClick={(e) => redirect(e, item.pk_lot)}>
                          <CardMedia
                            component="img"
                            alt="Foto Destaque"
                            height="140"
                            image={item.foto_destaque ? api.backendUrl + '/static/upload/lotes/' + item.pk_lot + '/' + item.foto_destaque : api.backendUrl + '/static/upload/lotes/no-image.jpg'}
                            title="Contemplative Reptile"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                              {item.cod_oper}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Qtd de Peças: {item.qtd_pecas}<br />
                                  Volume: {item.volume}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    )
                  })}
                </div>
              </div>
            </Box> */}
          </Collapse>
        </TableCell>
      </>
    )
  } else {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3}>
              Não há registros para detalhar
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  }

}

export default TableDetail;