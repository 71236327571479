import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import TableDetail from '../TableDetail'
import api from '../../services/api'
import { Modal } from 'react-bootstrap'
import FrTextInput from '../FrTextInput';
import $ from 'jquery';
import './index.css';
import Input from "@material-ui/core/Input";
import Tooltip from '@material-ui/core/Tooltip';
import { menu } from 'react-icons-kit/feather/menu';
import FrSelect from "../FrSelect";
import Dialog from '@material-ui/core/Dialog';
// import { download } from 'react-icons-kit/icomoon/download';
import { download2 } from 'react-icons-kit/icomoon/download2';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { search } from 'react-icons-kit/icomoon/search';
import { cross } from 'react-icons-kit/icomoon/cross';
import { mCPF, mCNPJ } from '../../utils/masks';
import { Typography } from '@material-ui/core';
import download from 'downloadjs';

const moment = require("moment");

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStylesBootstrap = makeStyles(theme => ({
  tooltip: {
    backgroundColor: "transparent",
    color: theme.palette.common.white,
  }
}));

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: '#45688d'

    },
    "& .Mui-selected:hover": {
      backgroundColor: '#45688d'

    },
    width: '100%',
  },
  container: {
    maxHeight: '58vh',
    minHeight: '58vh'
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  // console.log(order, orderBy)
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  /* if (!Array.isArray(array)) return []; */
  //console.log(array, comparator)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log(stabilizedThis);
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#45688d',
    color: theme.palette.common.white,

  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


export default function FrTable(props) {
  const classes = useStyles();
  const tooltipClass = useStylesBootstrap();

  const createSortHandler = (property) => (event) => {
    console.log(props.order, props.orderBy)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // console.log(orderBy, order)
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10);
  const [data, setData] = useState(props.data);
  const [columns, setColumns] = useState(props.columns);
  const [order, setOrder] = React.useState(props.order || 'asc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy || props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [obs, setObs] = React.useState('');
  const [widthOptions, setwidthOptions] = React.useState(100);
  const [rowEditar, setRowEditar] = React.useState(0)
  const [prioridade, setPrioridade] = React.useState(0)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0, fk_gpu: 0 })));
  const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }]);
  const [comboPublico, setComboPublico] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }]);
  const [comboTmo, setComboTmo] = useState([{ value: '', display: '' }]);
  const [comboLib, setComboLib] = useState();
  const [fk_tmo, setfk_tmo] = React.useState(0)
  const [publico, setPublico] = React.useState('')
  const [fk_usu, setfk_usu] = React.useState(0)
  const [pk_cha, setpk_cha] = React.useState(0)
  const [responsavel, setResponsavel] = React.useState(0)
  const [showModalAtendimento, setShowModalAtendimento] = React.useState(false);
  const [editPri, setEditPri] = useState(false)
  const [editPriField, setEditPriField] = useState()

  const [dataArq, setDataArq] = useState([]);

  const [liberador, setLiberador] = useState();
  const [temLiberador, setTemLiberador] = useState(false)


  useEffect(() => {
    // console.log(props.order)
    if (props.orderBy)
      setOrderBy(props.orderBy)
      setOrder(props.order)
    setData(props.data)
    setPage(0)
    if (props.widthOptions > 0)
      setwidthOptions(props.widthOptions)

    let prioridades = [];
    props.data.map((d) => {
      prioridades.push(d.prioridade);
    })
    setPrioridade(prioridades)
  }, [props.data])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])


  async function lancaMovimentos(e, id, responsavel, fk_cli) {
    e.preventDefault()
    let usuarios = await api.get('/usuarios/comboMov?fk_cli=' + fk_cli)
    // console.log(usuarios.data);
    setComboUsu(usuarios.data);
    setpk_cha(id)
    setResponsavel(responsavel)
    setfk_tmo(0)
    setObs('')
    if (auth.fk_gpu <= 2) {
      setPublico('N')
      setfk_usu(0)
      setComboTmo(await api.getComboData('tipo_movimentacao'));
    }
    else {
      setPublico('S')
      setfk_usu(responsavel)
      setComboTmo(await api.getComboData('tipo_movimentacao', 'externo'));
    }
    setShowModal(true)
  }

  function confirmaModal(e) {
    e.preventDefault()
    if ((fk_tmo) && (obs)) {
      setShowModal(false)
      swal.fire({
        title: 'ATENÇÃO',
        text: "Confirma lançamento?",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          
          let formData = new FormData();

          formData.append('fk_tmo', fk_tmo);
          formData.append('pk_cha', pk_cha);
          formData.append('fk_usu', fk_usu);
          formData.append('obs', obs);
          formData.append('publico', publico);
          formData.append('pk_usu', auth.user);

          formData.append('arquivoMov1', dataArq.arquivoMov1);
          formData.append('arquivoMov2', dataArq.arquivoMov2);
          formData.append('arquivoMov3', dataArq.arquivoMov3);
          formData.append('arquivoMov4', dataArq.arquivoMov4);
          formData.append('arquivoMov5', dataArq.arquivoMov5);
          formData.append('arquivoMov6', dataArq.arquivoMov6);

          if(dataArq.arq1){
            formData.append('file', dataArq.arq1, dataArq.arquivoMov1);
          }
          if(dataArq.arq2){
            formData.append('file', dataArq.arq2, dataArq.arquivoMov2);
          }
          if(dataArq.arq3){
            formData.append('file', dataArq.arq3, dataArq.arquivoMov3);
          }
          if(dataArq.arq4){
            formData.append('file', dataArq.arq4, dataArq.arquivoMov4);
          }
          if(dataArq.arq5){
            formData.append('file', dataArq.arq5, dataArq.arquivoMov5);
          }
          if(dataArq.arq6){
            formData.append('file', dataArq.arq6, dataArq.arquivoMov6);
          }

          api.post('/chamado_mov/add', formData).then(r => {
            if (r.status === 200) {
              swal.fire({
                title: 'Registro Adicionado',
                text: "O registro foi adicionado com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  setShowModal(false);
                  handleClickDetail(e, pk_cha);
                  clearFiles();

                  // let target = `https://wa.me/${'5551999637395'}/?text=${encodeURIComponent('Olá! Tudo bem? Segue link para prosseguir com o pagamento da adesão ao KSA Clube. Link: ' + r.data.url)}`
                  // window.open(target)
                }

              })
            }
          })
        } else
          setShowModal(true)
      })
    } else
      swal.fire({
        title: 'Erro!',
        text: "Informe o tipo de movimento e a descrição!",
        icon: "warning"
      });

  }

  const handleSelectedFileMov = (e) => {
    let file = e.target.files[0];
    let campo = e.target.name;
    console.log(e.target.name, e.target.files[0]);
    let auxValues = { ...dataArq }
    if (file) {
        if (campo == 'arquivoMov1') {
            auxValues.arq1 = file
            auxValues.arquivoMov1 = file.name
        }
        if (campo == 'arquivoMov2') {
            auxValues.arq2 = file
            auxValues.arquivoMov2 = file.name
        }
        if (campo == 'arquivoMov3') {
            auxValues.arq3 = file
            auxValues.arquivoMov3 = file.name
        }
        if (campo == 'arquivoMov4') {
            auxValues.arq4 = file
            auxValues.arquivoMov4 = file.name
        }
        if (campo == 'arquivoMov5') {
            auxValues.arq5 = file
            auxValues.arquivoMov5 = file.name
        }
        if (campo == 'arquivoMov6') {
            auxValues.arq6 = file
            auxValues.arquivoMov6 = file.name
        }

    } else {
        if (campo == 'arquivoMov1') {
            auxValues.arq1 = null
            auxValues.arquivoMov1 = null
        }
        if (campo == 'arquivoMov2') {
            auxValues.arq2 = null
            auxValues.arquivoMov2 = null
        }
        if (campo == 'arquivoMov3') {
            auxValues.arq3 = null
            auxValues.arquivoMov3 = null
        }
        if (campo == 'arquivoMov4') {
            auxValues.arq4 = null
            auxValues.arquivoMov4 = null
        }
        if (campo == 'arquivoMov5') {
            auxValues.arq5 = null
            auxValues.arquivoMov5 = null
        }
        if (campo == 'arquivoMov6') {
            auxValues.arq6 = null
            auxValues.arquivoMov6 = null
        }

    }

    console.log(auxValues)
    setDataArq(auxValues)
}

const selectFileMov = (e, name) => {
    e.preventDefault();

    if (!name) {
      if (!dataArq.arquivoMov1) name = 'arquivoMov1'
        else if (!dataArq.arquivoMov2) name = 'arquivoMov2'
          else if (!dataArq.arquivoMov3) name = 'arquivoMov3'
            else if (!dataArq.arquivoMov4) name = 'arquivoMov4'
              else if (!dataArq.arquivoMov5) name = 'arquivoMov5'
                else if (!dataArq.arquivoMov6) name = 'arquivoMov6'
    }

    $(`#` + name).click();

}

// const handleView = (n) => {
//   let path = api.backendUrl + '/static/upload/chamados/' + data.pk_cha + '/'
//   if (n == 1)
//       window.open(path + dataArq.arquivo1, '_blank');
//   if (n == 2)
//       window.open(path + dataArq.arquivo2, '_blank');
//   if (n == 3)
//       window.open(path + dataArq.arquivo3, '_blank');
//   if (n == 4)
//       window.open(path + dataArq.arquivo4, '_blank');
//   if (n == 5)
//       window.open(path + dataArq.arquivo5, '_blank');
//   if (n == 6)
//       window.open(path + dataArq.arquivo6, '_blank');

// }

const handleViewMov = (n, i) => {
  // let path = api.backendUrl + '/static/upload/chamados_mov/' + props.data[i].fk_cha + '/' + props.data[i].pk_cmo + '/';
  // console.log(i, props.data, props.data[i], path)
  // if (n == 1)
  //     window.open(path + props.data[i].arquivo1, '_blank');
  // if (n == 2)
  //     window.open(path + props.data[i].arquivo2, '_blank');
  // if (n == 3)
  //     window.open(path + props.data[i].arquivo3, '_blank');
  // if (n == 4)
  //     window.open(path + props.data[i].arquivo4, '_blank');
  // if (n == 5)
  //     window.open(path + props.data[i].arquivo5, '_blank');
  // if (n == 6)
  //     window.open(path + props.data[i].arquivo6, '_blank');

    // console.log(props.data[i])

    let arq = '';
    let cmo = props.data[i].pk_cmo;
    if (n == 1)
        arq = props.data[i].arquivoMov1;
    if (n == 2)
        arq = props.data[i].arquivoMov2;
    if (n == 3)
        arq = props.data[i].arquivoMov3;
    if (n == 4)
        arq = props.data[i].arquivoMov4;
    if (n == 5)
        arq = props.data[i].arquivoMov5;
    if (n == 6)
        arq = props.data[i].arquivoMov6;

    // console.log(arq, props.data[i].pk_cmo)

    api.post(`/chamado_mov/visualizarArquivo?arquivo=${arq}&fk_cmo=${cmo}&cha=${props.data[i].fk_cha}`, {}, {responseType: 'blob'}).then(r => {
        console.log(r);
        var blob = new Blob([r.data], {
            type: r.data.type
        });
        // console.log(blob)
        // $("#img1").attr("src", window.URL.createObjectURL(blob));
        window.open(window.URL.createObjectURL(blob))
    });

}

// const handleDownload = (n) => {
//   // let arq;
//   let cham = dataArq.pk_cha;
//   // console.log(arq, cham);

//   // if (n == 1)
//   //     arq = dataArq.arquivo1;
//   // if (n == 2)
//   //     arq = dataArq.arquivo2;
//   // if (n == 3)
//   //     arq = dataArq.arquivo3;
//   // if (n == 4)
//   //     arq = dataArq.arquivo4;
//   // if (n == 5)
//   //     arq = dataArq.arquivo5;
//   // if (n == 6)
//   //     arq = dataArq.arquivo6;
//   // //console.log(arq)
//   // // let queryObject = {pk_cha: data.pk_cha, arquivo: arq};
//   // // api.get('/chamados/downloadAnexo', {params: queryObject});
//   // const url = api.backendUrl + `/api/chamados/downloadAnexo?fk_cha=${cham}&arquivo=${arq}`;
//   // var link = document.createElement("a");
//   // link.href = url;
//   // document.body.appendChild(link);
//   // link.click();
//   // link.remove();
//     api.post('chamados/downloadAnexo?&arquivo='+dataArq[n].arquivo+'&fk_cha='+cham, {}, {responseType: 'blob'}).then((response) => {
//       if(response.data.type == 'application/json'){
//         swal.fire({
//             title: 'Arquivo Não Encontrado',
//             text: "Arquivo não foi encontrado no servidor!",
//             icon: 'error',
//         })
//       } else {
//         console.log(response, dataArq[n].arquivo)
//           var blob = new Blob([response.data], {
//             type: response.data.type
//           });
//           download(blob, dataArq[n].arquivo)
//       }
//     })
// }

const handleDownloadMov = (n, i) => {
  let arq;
  let cmo = props.data[i].pk_cmo;
  console.log(arq, cmo);

  if (n == 1)
      arq = props.data[i].arquivoMov1;
  if (n == 2)
      arq = props.data[i].arquivoMov2;
  if (n == 3)
      arq = props.data[i].arquivoMov3;
  if (n == 4)
      arq = props.data[i].arquivoMov4;
  if (n == 5)
      arq = props.data[i].arquivoMov5;
  if (n == 6)
      arq = props.data[i].arquivoMov6;
  //console.log(arq)
  // let queryObject = {pk_cha: data.pk_cha, arquivo: arq};
  // api.get('/chamados/downloadAnexo', {params: queryObject});
  // const url = api.backendUrl + `/api/chamados_mov/downloadAnexo?fk_cmo=${cmo}&arquivo=${arq}`;
  // var link = document.createElement("a");
  // link.href = url;
  // document.body.appendChild(link);
  // link.click();
  // link.remove();
  api.post(`chamado_mov/downloadAnexo?arquivo=${arq}&fk_cmo=${cmo}&cha=${props.data[i].fk_cha}`, {}, {responseType: 'blob'}).then((response) => {
    if(response.data.type == 'application/json'){
      swal.fire({
          title: 'Arquivo Não Encontrado',
          text: "Arquivo não foi encontrado no servidor!",
          icon: 'error',
      })
    } else {
      console.log(response, props.data[i].arquivo)
        var blob = new Blob([response.data], {
          type: response.data.type
        });
        download(blob, arq)
    }
  })
}

  function escondeModal(e) {
    e.preventDefault();
    setShowModal(false);
    setShowModalAtendimento(false);
    clearFiles();
  }

  const quebraLinha = (texto) => {
    let linhas = texto.split('\r');
    let deets = [];
    linhas.map(linha => {
        deets.push(<><>{linha}</> <br/></>)
      });
    //console.log(deets);
    return deets;
  }

  function handleDelete(e, id, table) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/' + table + '/delete/' + id).then(r => {
          console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Registro Excluído',
              text: "O registro foi excluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  const clearFiles = () => {
    const auxValues = { ...dataArq };
  
    auxValues.arquivoMov1 = '';
    auxValues.arq1 = '';
  
    auxValues.arquivoMov2 = '';
    auxValues.arq2 = '';
  
    auxValues.arquivoMov3 = '';
    auxValues.arq3 = '';
  
    auxValues.arquivoMov4 = '';
    auxValues.arq4 = '';
  
    auxValues.arquivoMov5 = '';
    auxValues.arq5 = '';
  
    auxValues.arquivoMov6 = '';
    auxValues.arq6 = '';
  
    setDataArq(auxValues);
  }

  const handleDeleteFile = (n) => {
    const auxValues = { ...dataArq };
      if (n == 1) {
        auxValues.arquivoMov1 = '';
        auxValues.arq1 = '';
      } if (n == 2) {
        auxValues.arquivoMov2 = '';
        auxValues.arq2 = '';
      } if (n == 3) {
        auxValues.arquivoMov3 = '';
        auxValues.arq3 = '';
      } if (n == 4) {
        auxValues.arquivoMov4 = '';
        auxValues.arq4 = '';
      } if (n == 5) {
        auxValues.arquivoMov5 = '';
        auxValues.arq5 = '';
      } if (n == 6) {
        auxValues.arquivoMov6 = '';
        auxValues.arq6 = '';
      }
      setDataArq(auxValues);
  }

  const handleDeleteFileMov = (n, i) => {
      if (n == 1) {
        props.data[i].arquivoMov1 = '';
        props.data[i].arq1 = '';
      } if (n == 2) {
        props.data[i].arquivoMov2 = '';
        props.data[i].arq2 = '';
      } if (n == 3) {
        props.data[i].arquivoMov3 = '';
        props.data[i].arq3 = '';
      } if (n == 4) {
        props.data[i].arquivoMov4 = '';
        props.data[i].arq4 = '';
      } if (n == 5) {
        props.data[i].arquivoMov5 = '';
        props.data[i].arq5 = '';
      } if (n == 6) {
        props.data[i].arquivoMov6 = '';
        props.data[i].arq6 = '';
      }
  }

  async function atender (e, id) {
    e.preventDefault()
    let lib;
    props.data.map((p) => {
      if (id == p.pk_cha){
        setLiberador(p.fk_usu_dev)
        lib = p.fk_usu_dev;
      }
    })
    
    if (lib != null) {
      setTemLiberador(true)
    } else {
      setTemLiberador(false)
    };
    await api.get('/usuarios/comboReq?interno=S').then(r => {
      setComboLib(r.data);
    });    
    setpk_cha(id)
    setObs('')
    setShowModalAtendimento(true)
  }


  function confirmaAtendimento(e) {
    e.preventDefault()
    setShowModalAtendimento(false)

    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma atendimento do chamado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/chamados/atender?pk_cha=' + pk_cha + '&fk_usu=' + auth.user + '&resolucao=' + obs).then(r => {
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Chamado atendido',
              text: "Chamado atendido com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Ocorreu um erro! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function encerrar(e, id) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Confirma encerramento do chamado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/chamados/encerrar?pk_cha=' + id + '&fk_usu=' + auth.user).then(r => {
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Chamado encerrado',
              text: "Chamado encerrado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "Ocorreu um erro! " + r.data.substr(6, r.data.length),
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function atualiza(pk_cha, prioridade) {
    api.get('/chamados/prioridade?pk_cha=' + pk_cha + '&prioridade=' + prioridade).then(r => {
      if (r.status === 200) {
        setRowEditar(0)
        let event = new Event('build');
        props.getData(event, true);
        setEditPri(false)
      }
    })
  }

  const mostraEditPri = (i) => {
    setEditPri(true)
    setEditPriField(i)
    console.log(orderBy)
    let orgData = [...props.data];
    stableSort(orgData, getComparator(order, orderBy))
    console.log(orgData)
    props.setData(orgData)
  }


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      if (props.setSelected)
        props.setSelected(newSelecteds)

      return;
    }
    setSelected([]);
    if (props.setSelected)
      props.setSelected([])

  };

  const handleClick = (event, name) => {
    if (props.mostraCheck) {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      if (props.setSelected)
        props.setSelected(newSelected)
    }
  };

  const handleClickDetail = (event, name) => {
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;

  const handleChange = async (e) => {

    switch (e.target.name) {
      case 'fk_tmo':
        setfk_tmo(e.target.value);
        break
      case 'fk_usu':
        setfk_usu(e.target.value);
        break
      case 'publico':
        setPublico(e.target.value);
        break
      case 'obs':
        setObs(e.target.value);
        break
      case 'prioridade':
        let auxValues = stableSort(props.data, getComparator(order, orderBy));
        console.log(e.target.id, auxValues[e.target.id])
        auxValues[e.target.id].prioridade = e.target.value;
        props.setData(auxValues)
        break     
      case 'liberador':
        setLiberador(e.target.value)

    }
  };

  const separaArquivosMov = (arquivos, i) => {
    
    // console.log(arquivos, i);
    // console.log(props.data[i])

    let cmo = props.data[i].pk_cmo;
    let cha = props.data[i].fk_cha;

    let arq = arquivos ? arquivos.split(',') : null;

    if(arq){      

      arq.map((item, index) => {

      // console.log(index, props.data[index])
      // console.log(arq[index])
        
      let pos = 31 + String(cmo).length + String(cha).length;

      // console.log(i, cmo, String(cmo).length , cha, String(cha).length)

      let nomeArq = item.substring(pos, 100)

      if (index == 0)
        props.data[i].arquivoMov1 = nomeArq
      if (index == 1)
        props.data[i].arquivoMov2 = nomeArq
      if (index == 2)
        props.data[i].arquivoMov3 = nomeArq
      if (index == 3)
        props.data[i].arquivoMov4 = nomeArq
      if (index == 4)
        props.data[i].arquivoMov5 = nomeArq
      if (index == 5)
        props.data[i].arquivoMov6 = nomeArq

      // console.log(props.data[i])
      // console.log(nomeArq)  
      })        
    }
  }


  const onClick = (e, prioridade, pk_cha) => {

    if (auth.fk_gpu <= 2) {
      setPrioridade(prioridade)
      setRowEditar(pk_cha)
      console.log(prioridade, pk_cha)
    }

  };

  return (

    <div>

      <div hidden={!showModalAtendimento}>
        <Dialog aria-labelledby="simple-dialog-title" open={showModalAtendimento} maxWidth={'lg'}>

          <div className="filtersDiv" style={{ padding: '10px' }}>
            <h5>Atendimento de chamado nº {pk_cha}</h5>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <FrSelect 
                value={liberador || ''}
                onChange={handleChange}
                name="liberador"
                id="liberador"
                label='Usuário Liberador'
                data={comboLib}
                style={{ width: 180 }}
                disabled={temLiberador}
              />

              <FrTextInput
                maxLength='500'
                value={obs || ''}
                onChange={handleChange}
                name="obs"
                id="obs"
                color="#49573B"
                label="Resolução"
                variant="filled"
                size="small"
                style={{ width: 450 }}
                multiline
                rows="30"
                rowsMax="30"
                fullWidth
              />
            </div>
            


          </div>
          <div className="regButtonsDiv" style={{ padding: '10px' }}>
            <button onClick={confirmaAtendimento}> Confirmar </button>
            <button onClick={escondeModal}> Sair </button>
          </div>
        </Dialog>
      </div>


      <div hidden={!showModal}>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} maxWidth={'lg'}>

          <div className="filtersDiv" style={{ padding: '10px' }}>
            <h5>Lançamento de Movimento chamado nº {pk_cha}</h5>

            <FrSelect
              value={fk_tmo || ''}
              onChange={handleChange}
              name="fk_tmo"
              id="fk_tmo"
              label='Tipo de Movimento'
              data={comboTmo}
              style={{ width: 150 }}
            />
            <FrSelect
              value={fk_usu || ''}
              onChange={handleChange}
              name="fk_usu"
              id="fk_usu"
              label='Atribuir para'
              data={comboUsu}
              style={{ width: 150 }}
              hidden={auth.fk_gpu > 2}

            />

            <FrSelect
              value={publico || ''}
              onChange={handleChange}
              name="publico"
              id="publico"
              label='Público'
              data={comboPublico}
              style={{ width: 100 }}
              hidden={auth.fk_gpu > 2}
            />
            <br />
            <FrTextInput
              maxLength='1000'
              value={obs || ''}
              onChange={handleChange}
              name="obs"
              id="obs"
              color="#49573B"
              label="Descrição"
              variant="filled"
              size="small"
              style={{ width: 450 }}
              multiline
              rows="40"
              rowsMax="40"
              fullWidth
            />
            <br />
            <h5>
                Anexos
            </h5>
            <div className="grupoEdits">
                <button onClick={(event) => selectFileMov(event, '')}>Anexar Arquivo</button>

                <div className="anexos" style={{display: 'flex', flexDirection: 'column'}}>
                  <input
                      type="file"
                      name="arquivoMov1"
                      id="arquivoMov1"
                      onChange={handleSelectedFileMov}
                      hidden
                  />
                  <FrTextInput
                      value={dataArq.arquivoMov1 || ''}
                      onChange={handleChange}
                      name="arquivoMov1"
                      id="arquivoMov1"
                      color="#49573B"
                      label={dataArq.arquivoMov1 ? "Arquivo" : "Clique para anexar um arquivo"}
                      variant="outlined"
                      size="small"
                      disabled
                      style={{ width: 300 }}
                      hidden={!dataArq.arquivoMov1}
                      // startAdornment={
                      //     <div style={{ display: 'flex', flexDirection: 'row'}}>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                      //             <Icon icon={search} size={14} onClick={(e) => { handleView(1); }} />
                      //         </div>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                      //             <Icon icon={download2} size={14} onClick={(e) => { handleDownload(1); }} />
                      //         </div>
                      //     </div>

                      // }
                      endAdornment={
                          <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFile(1); }} />
                          </div>
                      }
                  />

                  <input
                      type="file"
                      name="arquivoMov2"
                      id="arquivoMov2"
                      onChange={handleSelectedFileMov}
                      hidden
                  />
                  <FrTextInput
                      value={dataArq.arquivoMov2 || ''}
                      onChange={handleChange}
                      name="arquivoMov2"
                      id="arquivoMov2"
                      color="#49573B"
                      label={dataArq.arquivoMov2 ? "Arquivo" : "Clique para anexar um arquivo"}
                      variant="outlined"
                      size="small"
                      disabled
                      style={{ width: 300 }}
                      hidden={!dataArq.arquivoMov2}
                      // startAdornment={
                      //     <div style={{ display: 'flex', flexDirection: 'row'}}>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                      //             <Icon icon={search} size={14} onClick={(e) => { handleView(2); }} />
                      //         </div>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                      //             <Icon icon={download2} size={14} onClick={(e) => { handleDownload(2); }} />
                      //         </div>
                      //     </div>

                      // }
                      endAdornment={
                          <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFile(2); }} />
                          </div>
                      }
                  />

                  <input
                      type="file"
                      name="arquivoMov3"
                      id="arquivoMov3"
                      onChange={handleSelectedFileMov}
                      hidden
                  />
                  <FrTextInput
                      value={dataArq.arquivoMov3 || ''}
                      onChange={handleChange}
                      name="arquivoMov3"
                      id="arquivoMov3"
                      color="#49573B"
                      label={dataArq.arquivoMov3 ? "Arquivo" : "Clique para anexar um arquivo"}
                      variant="outlined"
                      size="small"
                      disabled
                      style={{ width: 300 }}
                      hidden={!dataArq.arquivoMov3}
                      // startAdornment={
                      //     <div style={{ display: 'flex', flexDirection: 'row'}}>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                      //             <Icon icon={search} size={14} onClick={(e) => { handleView(3); }} />
                      //         </div>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                      //             <Icon icon={download2} size={14} onClick={(e) => { handleDownload(3); }} />
                      //         </div>
                      //     </div>

                      // }
                      endAdornment={
                          <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFile(3); }} />
                          </div>
                      }
                  />

                  <input
                      type="file"
                      name="arquivoMov4"
                      id="arquivoMov4"
                      onChange={handleSelectedFileMov}
                      hidden
                  />
                  <FrTextInput
                      value={dataArq.arquivoMov4 || ''}
                      onChange={handleChange}
                      name="arquivoMov4"
                      id="arquivoMov4"
                      color="#49573B"
                      label={dataArq.arquivoMov4 ? "Arquivo" : "Clique para anexar um arquivo"}
                      variant="outlined"
                      size="small"
                      disabled
                      style={{ width: 300 }}
                      hidden={!dataArq.arquivoMov4}
                      // startAdornment={
                      //     <div style={{ display: 'flex', flexDirection: 'row'}}>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                      //             <Icon icon={search} size={14} onClick={(e) => { handleView(4); }} />
                      //         </div>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                      //             <Icon icon={download2} size={14} onClick={(e) => { handleDownload(4); }} />
                      //         </div>
                      //     </div>

                      // }
                      endAdornment={
                          <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFile(4); }} />
                          </div>
                      }

                  />

                  <input
                      type="file"
                      name="arquivoMov5"
                      id="arquivoMov5"
                      onChange={handleSelectedFileMov}
                      hidden
                  />
                  <FrTextInput
                      value={dataArq.arquivoMov5 || ''}
                      onChange={handleChange}
                      name="arquivoMov5"
                      id="arquivoMov5"
                      color="#49573B"
                      label={dataArq.arquivoMov5 ? "Arquivo" : "Clique para anexar um arquivo"}
                      variant="outlined"
                      size="small"
                      disabled
                      style={{ width: 300 }}
                      hidden={!dataArq.arquivoMov5}
                      // startAdornment={
                      //     <div style={{ display: 'flex', flexDirection: 'row'}}>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                      //             <Icon icon={search} size={14} onClick={(e) => { handleView(5); }} />
                      //         </div>
                      //         <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                      //             <Icon icon={download2} size={14} onClick={(e) => { handleDownload(5); }} />
                      //         </div>
                      //     </div>

                      // }
                      endAdornment={
                          <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFile(5); }} />
                          </div>
                      }

                  />


                  <input
                      type="file"
                      name="arquivoMov6"
                      id="arquivoMov6"
                      onChange={handleSelectedFileMov}
                      hidden
                  />
                  <FrTextInput
                      value={dataArq.arquivoMov6 || ''}
                      onChange={handleChange}
                      name="arquivoMov6"
                      id="arquivoMov6"
                      color="#49573B"
                      label={dataArq.arquivoMov6 ? "Arquivo" : "Clique para anexar um arquivo"}
                      variant="outlined"
                      size="small"
                      disabled
                      style={{ width: 300 }}
                      hidden={!dataArq.arquivoMov6}
                      // startAdornment={                                                
                      //     <div>
                      //         <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                      //             <Icon icon={search} size={14} onClick={(e) => { handleView(6); }} />
                      //         </div>
                      //         <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                      //             <Icon icon={search} size={14} onClick={(e) => { handleDownload(6); }} />
                      //         </div>
                      //     </div>

                      // }
                      endAdornment={
                          <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFile(6); }} />
                          </div>
                      }
                  />

                </div>
            </div>


          </div>
          <div className="regButtonsDiv" style={{ padding: '10px' }}>
            <button onClick={confirmaModal}> Confirmar </button>
            <button onClick={escondeModal}> Sair </button>
          </div>
        </Dialog>
      </div>

      <div >
        <ThemeProvider theme={theme}>
          {(data.length === 0) ? (
            <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}
              {props.mensagemSemRegistro ? props.mensagemSemRegistro : ''}

            </div>
          ) : (
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>

                      {(!props.mostraCheck) ? (<></>) :

                        <StyledTableCell padding="checkbox">
                          <FrCheckBox
                            indeterminate={selected.length > 0 && selected.length < data.length}
                            checked={data.length > 0 && selected.length === data.length}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                        </StyledTableCell>
                      }

                      {props.detail ? <StyledTableCell style={{ minWidth: 1, width: 1 }} /> : (<></>)}

                      {(!props.readOnly) ? (
                        <StyledTableCell
                          style={{ minWidth: widthOptions, width: widthOptions }}
                          align={'center'}
                        >
                          Opções
                        </StyledTableCell>) : (<></>)}
                      {columns.map((column) => (
                        !column.hidden ?
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            hidden={column.hidden}
                          >
                            <TableSortLabel
                              id={column.id}
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={(column.id != 'cnpj' && column.id != 'insc_estadual') ? createSortHandler((column.order) ? column.order : column.id) : ''}
                            >
                              {column.label}
                            </TableSortLabel>
                          </StyledTableCell>
                        :
                          <></>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const isItemSelected = isSelected(row[props.codeName]);
                      const isItemOpened = isOpened(row[props.codeName]);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      if (props.detail) {
                        return (
                          <>
                            <TableRow aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                              {(!props.mostraCheck) ? (<></>) :


                                <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                                  <FrCheckBox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>

                              }

                              <TableCell style={{ minWidth: 1, width: 1 }} >
                                <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                                  {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                              </TableCell>
                              {!props.readOnly ? (
                                <TableCell>
                                  <Tooltip title="Editar">
                                    <Link to={"/suporte/" + props.regName + "/registro?codigo=" + row[props.codeName] + "&manut=S"}>
                                      <Icon className="tableEdit" icon={ic_mode_edit} size={16} />
                                    </Link>
                                  </Tooltip>

                                  {(props.enableDel == 'S') ? (<Icon className="tableDelete" icon={ic_delete} size={16} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />)
                                    : (<></>)}

                                  {(props.regName == "chamados") ? <Tooltip title="Movimentos">
                                    <Link to={""}>

                                      <Icon className="tableDelete" icon={menu} size={16} onClick={(e) => lancaMovimentos(e, row['pk_cha'], row['fk_usu_responsavel'], row['fk_cli'])} />
                                    </Link>
                                  </Tooltip>
                                    : (<></>)}


                                  {((props.regName == "chamados") && (auth.fk_gpu <= 2) && (row["resolvido"] == 'N') && row['fk_csi'] != 7) ? <Tooltip title="Atender">
                                    <Link to={""}>

                                      <Icon className="tableDelete" icon={download2} size={16} onClick={(e) => atender(e, row['pk_cha'])} />
                                    </Link>
                                  </Tooltip>
                                    : (<></>)}

                                  {((props.regName == "chamados") && auth.fk_gpu <= 2 && (row["resolvido"] == 'N') && row['fk_csi'] == 7) ? <Tooltip title="Encerrar Chamado">
                                    <Link to={""}>

                                      <Icon className="tableDelete" icon={checkmark} size={16} onClick={(e) => encerrar(e, row['pk_cha'])} />
                                    </Link>
                                  </Tooltip>
                                    : (<></>)}

                                </TableCell>
                              ) : (<></>)}
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.image) {
                                  return (
                                    <TableCell key={column.id} align={column.align} hidden={column.hidden}>
                                      <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                    </TableCell>
                                  )
                                } else if(column.id == 'pk_cha'){
                                  return (
                                    <Tooltip arrow placement="top"
                                      title={
                                        <React.Fragment>
                                            <Typography style={{ padding: 10, backgroundColor: '#45688d', border: '3px solid #c0c0c0' }}>
                                              {'Detalhamento:'}
                                              <br />
                                              {quebraLinha(row['detalhamento'])}
                                            </Typography>
                                        </React.Fragment>
                                      }
                                    >
                                      <TableCell hidden={column.hidden} key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number' ? column.format(value) : (props.regName == 'clientes' && column.id == 'cnpj_cpf') ? ((value.length == 11) ? mCPF(value) : mCNPJ(value)) : value } 
                                      </TableCell>
                                    </Tooltip>                                  
                                  );
                                } else if(column.id == 'csi_cor'){
                                  return(
                                    <Tooltip arrow placement="top"
                                        title={
                                          <React.Fragment>
                                              <Typography style={{ padding: 10, backgroundColor: '#45688d', border: '3px solid #c0c0c0' }}>
                                                {row['nomesit']}
                                              </Typography>
                                          </React.Fragment>
                                        }
                                      >
                                        <TableCell hidden={column.hidden} key={column.id} align={column.align}>
                                          {<div style={{width: '20px', height: '20px', border: '1px solid black', borderRadius: '10px', backgroundColor: row['cor_csi'], display: 'flex', justifyContent: 'center', justifyItems: 'center'}}>
                                                
                                          </div>}
                                        </TableCell>
                                      </Tooltip>
                                    )
                                  } else if (column.id == 'prioridade') {
                                    // <CustomTableCell {...{ row, name: "prioridade", onChange, onClick }} />
                                    if (auth.fk_gpu == 1) {
                                      return (
                                        <TableCell> {/* gancho */}
                                        <div hidden={editPri && editPriField == index} onClick={()=> mostraEditPri(index)}>
                                          {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </div>
                                          <Input
                                            value={row['prioridade']}
                                            name='prioridade'
                                            onChange={handleChange}
                                            id={index}
                                            style={{ width: '20px', fontSize: '12px' }}
                                            // disableUnderline={true}
                                            hidden={!(editPri && editPriField == index)}
                                            onKeyPress={(e) => {
                                              if (e.key === "Enter") {
                                                atualiza(row['pk_cha'], row['prioridade']);
                                              }
                                            }}
                                          />
                                        </TableCell>
                                      )
                                    } else
                                      return (
    
                                        <TableCell hidden={column.hidden} key={column.id} align={column.align} onClick={(e) => onClick(e, row['prioridade'], row['pk_cha'])}>
                                          {column.format && typeof value === 'number' ? column.format(value) : value}
                                        </TableCell>
                                      )
    
                                  } else if(column.id == 'arquivos'){
                                  return(
                                    <TableCell hidden={column.hidden} key={column.id} align={column.align}>                                    
                                      {separaArquivosMov(row['arquivos'], index)}

                                      <FrTextInput
                                      value={props.data[index].arquivoMov1 || ''}
                                      onChange={handleChange}
                                      name="arquivoMov1"
                                      id="arquivoMov1"
                                      color="#49573B"
                                      variant="outlined"
                                      size="small"
                                      disabled
                                      style={{ width: 300 }}
                                      hidden={!props.data[index].arquivoMov1}
                                      startAdornment={
                                          <div style={{ display: 'flex', flexDirection: 'row'}}>
                                              <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                  <Icon icon={search} size={14} onClick={(e) => { handleViewMov(1, index); }} />
                                              </div>
                                              <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                  <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(1, index); }} />
                                              </div>
                                          </div>

                                      }
                                      endAdornment={
                                          <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(1, index); }} />
                                          </div>
                                      }
                                    />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov2 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov2"
                                          id="arquivoMov2"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov2}
                                          startAdornment={
                                              <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(2, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                      <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(2, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(2, index); }} />
                                              </div>
                                          }
                                      />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov3 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov3"
                                          id="arquivoMov3"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov3}
                                          startAdornment={
                                              <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(3, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                      <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(3, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(3, index); }} />
                                              </div>
                                          }
                                      />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov4 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov4"
                                          id="arquivoMov4"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov4}
                                          startAdornment={
                                              <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(4, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                      <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(4, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(4, index); }} />
                                              </div>
                                          }

                                      />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov5 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov5"
                                          id="arquivoMov5"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov5}
                                          startAdornment={
                                              <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(5, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                      <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(5, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(5, index); }} />
                                              </div>
                                          }

                                      />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov6 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov6"
                                          id="arquivoMov6"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov6}
                                          startAdornment={                                                
                                              <div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(6, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleDownloadMov(6, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(6, index); }} />
                                              </div>
                                          }
                                      />
                                    </TableCell>
                                  )
                                } else {
                                  return (
                                    !column.hidden ?
                                    <TableCell key={column.id} align={column.align} hidden={column.hidden}>
                                      {column.format && typeof value === 'number' ? column.format(value) : (props.regName == 'clientes' && column.id == 'cnpj_cpf') ? ((value.length == 11) ? mCPF(value) : mCNPJ(value)) : value }
                                    </TableCell>
                                    :
                                    <></>
                                  );
                                }
                              })}


                            </TableRow>
                            <TableRow>
                              <TableDetail isOpen={isItemOpened} detail={row[props.codeName]} />
                            </TableRow>
                          </>
                        );

                      } else {
                        return (
                          <TableRow onClick={(event) => handleClick(event, row[props.codeName])} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>

                            {(!props.mostraCheck) ? (<></>) :
                              <TableCell padding="checkbox">
                                <FrCheckBox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>

                            }

                            {(!props.readOnly) ? (
                              <TableCell >
                                <Tooltip title="Editar">

                                  <Link to={"/suporte/" + props.regName + "/registro?codigo=" + row[props.codeName]}>

                                    <Icon className="tableEdit" icon={ic_mode_edit} size={16} />
                                  </Link>
                                </Tooltip>

                                {(props.enableDel == 'S') ? (<Icon className="tableDelete" icon={ic_delete} size={16} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />)
                                  : (<></>)}


                              </TableCell>
                            ) : (<></>)}



                            {columns.map((column, i) => {
                              const value = row[column.id];
                              if (column.image) {
                                return (
                                  <TableCell key={column.id} align={column.align} hidden={column.hidden}>
                                    <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                                  </TableCell>
                                )
                              } else if (column.id == 'prioridade') {
                                // <CustomTableCell {...{ row, name: "prioridade", onChange, onClick }} />
                                if (auth.fk_gpu <= 2) {
                                  return (
                                    <Input
                                      value={prioridade}
                                      name='prioridade'
                                      onChange={handleChange}
                                      style={{ width: '20px' }}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          atualiza(row['pk_cha'], prioridade);
                                        }
                                      }}
                                    />
                                  )
                                } else
                                  return (

                                    <TableCell hidden={column.hidden} key={column.id} align={column.align} onClick={(e) => onClick(e, row['prioridade'], row['pk_cha'])}>
                                      {column.format && typeof value === 'number' ? column.format(value) : value}
                                    </TableCell>
                                  )

                              } else if(column.id == 'arquivos'){
                                return(
                                  <TableCell key={column.id} align={column.align}>
                                    {separaArquivosMov(row['arquivos'], index)}

                                    <FrTextInput
                                      value={props.data[index].arquivoMov1 || ''}
                                      onChange={handleChange}
                                      name="arquivoMov1"
                                      id="arquivoMov1"
                                      color="#49573B"
                                      variant="outlined"
                                      size="small"
                                      disabled
                                      style={{ width: 300 }}
                                      hidden={!props.data[index].arquivoMov1}
                                      startAdornment={
                                          <div style={{ display: 'flex', flexDirection: 'row'}}>
                                              <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                  <Icon icon={search} size={14} onClick={(e) => { handleViewMov(1, index); }} />
                                              </div>
                                              <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                  <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(1, index); }} />
                                              </div>
                                          </div>

                                      }
                                      endAdornment={
                                          <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                              <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(1, index); }} />
                                          </div>
                                      }
                                    />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov2 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov2"
                                          id="arquivoMov2"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov2}
                                          startAdornment={
                                              <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(2, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                      <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(2, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(2, index); }} />
                                              </div>
                                          }
                                      />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov3 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov3"
                                          id="arquivoMov3"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov3}
                                          startAdornment={
                                              <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(3, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                      <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(3, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(3, index); }} />
                                              </div>
                                          }
                                      />

                                      <FrTextInput
                                          value={props.data[index].arquivoMov4 || ''}
                                          onChange={handleChange}
                                          name="arquivoMov4"
                                          id="arquivoMov4"
                                          color="#49573B"
                                          variant="outlined"
                                          size="small"
                                          disabled
                                          style={{ width: 300 }}
                                          hidden={!props.data[index].arquivoMov4}
                                          startAdornment={
                                              <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                      <Icon icon={search} size={14} onClick={(e) => { handleViewMov(4, index); }} />
                                                  </div>
                                                  <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                      <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(4, index); }} />
                                                  </div>
                                              </div>

                                          }
                                          endAdornment={
                                              <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                  <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(4, index); }} />
                                              </div>
                                          }

                                      />

                                    <FrTextInput
                                        value={props.data[index].arquivoMov5 || ''}
                                        onChange={handleChange}
                                        name="arquivoMov5"
                                        id="arquivoMov5"
                                        color="#49573B"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        style={{ width: 300 }}
                                        hidden={!props.data[index].arquivoMov5}
                                        startAdornment={
                                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                    <Icon icon={search} size={14} onClick={(e) => { handleViewMov(5, index); }} />
                                                </div>
                                                <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                    <Icon icon={download2} size={14} onClick={(e) => { handleDownloadMov(5, index); }} />
                                                </div>
                                            </div>

                                        }
                                        endAdornment={
                                            <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(5, index); }} />
                                            </div>
                                        }

                                    />

                                    <FrTextInput
                                        value={props.data[index].arquivoMov6 || ''}
                                        onChange={handleChange}
                                        name="arquivoMov6"
                                        id="arquivoMov6"
                                        color="#49573B"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        style={{ width: 300 }}
                                        hidden={!props.data[index].arquivoMov6}
                                        startAdornment={                                                
                                            <div>
                                                <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                                                    <Icon icon={search} size={14} onClick={(e) => { handleViewMov(6, index); }} />
                                                </div>
                                                <div style={{ color: '#528b46', cursor: 'pointer' }}                                        >
                                                    <Icon icon={search} size={14} onClick={(e) => { handleDownloadMov(6, index); }} />
                                                </div>
                                            </div>

                                        }
                                        endAdornment={
                                            <div style={{ color: 'red', cursor: 'pointer' }}                                          >
                                                <Icon icon={cross} size={13} onClick={(e) => { handleDeleteFileMov(6, index); }} />
                                            </div>
                                        }
                                    />
                                  </TableCell>
                                  )
                              } else {
                                return (
                                  <TableCell hidden={column.hidden} key={column.id} align={column.align} style={{width: column.width}}>
                                    {column.format && typeof value === 'number' ? column.format(value) : (props.regName == 'clientes' && column.id == 'cnpj_cpf') ? ((value.length == 11) ? mCPF(value) : mCNPJ(value)) : value } 
                                  </TableCell>
                                );
                              }

                            })}
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
}
