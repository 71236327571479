import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(0),
      color: '#9c9595',
    },
    'label + &.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
    },

  },
  input: {
    borderRadius: 0,
    color: 'black',
    position: 'relative',
    // backgroundColor: '#F7EFEF',
    border: '1px solid #9c9595',
    // fontSize: '0.9rem',
    // padding: '10px 26px 10px 12px',
    height: '15px',
    paddingLeft: '10px',
    '.MuiInputBase-root:hover &:disabled': {
      border: '1px solid #9c9595',
      borderRadius: '0',
      transition: 'none',
      height: '15px',
    },
    '&:focus': {
      borderRadius: 0,
      borderColor: '#9c9595',
      borderWidth: '2px',
      backgroundColor: '#F7EFEF',
      height: '15px',
      paddingTop: '5px',
      paddingLeft: '9px'
    },
    '&.Mui-disabled': {
      border: '1px solid rgba(0, 0, 0, 0.26)',
      color: 'rgba(0, 0, 0, 0.38)',
      '&:hover': {
        borderColor: '#9c9595',
        borderRadius: '0',
      },
    },

  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({

  margin: {
    marginRight: '6px',
    marginBottom: '10px'
  },


  label: {
    margin: theme.spacing(-1.4),
    marginLeft: theme.spacing(1.5),
    backgroundColor: '#FAFAFA',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink': {
      padding: '5px',
      top: '0',
      // color: 'black'
    },
  },

}));

export default function CustomizedSelects(props) {
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.margin} style={props.style}>
        <InputLabel htmlFor="demo-customized-select-native" className={classes.label} disabled={props.disabled} hidden={props.hidden}>{props.label}</InputLabel>
        <NativeSelect
          id={props.id}
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          input={<BootstrapInput />}
          {...props}
        >
          <option aria-label="Nenhum" value="" />
          {
            props.data.map((item) => {
              return (<option value={item.value}>{item.display}</option>)
            })
          }

        </NativeSelect>
      </FormControl>
    </>
  );
}