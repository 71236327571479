import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Usuarios() {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboGrupoUsu, setComboGrupoUsu] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'nome', numeric: false, label: 'Nome', align: 'left', minWidth: 200 },
    { id: 'email', numeric: false, label: 'Login', align: 'left', minWidth: 150 },
    { id: 'nomegpu', numeric: false, label: 'Grupo de Usuário', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Usuários'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setComboGrupoUsu(await api.getComboData('grupo_usuarios'))
        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/suporte/usuarios?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/usuarios', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/suporte/usuarios');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Usuários</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.nome || ''}
                style={{ width: 330 }}
                name="nome"
                id="nome"
                color="#49573B"
                label="Nome"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                value={filter.pk_gpu || ''}
                style={{ width: 170 }}
                name="pk_gpu"
                id="pk_gpu"
                label='Grupo de Usuário'
                data={comboGrupoUsu}
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/suporte/usuarios/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="usuarios" columns={columns} searched={filtered} codeName="pk_usu" page={'Usuarios'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Usuarios;
