import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";
import { SketchPicker } from "react-color";

import NavBar from "../components/NavBar";
import FrTextInput from '../components/FrTextInput';
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";

import api from '../services/api';
import { conferePermissao, paramsToObject } from "../utils/functions";

import './styles/DefaultReg.css';
import FooterDelphus from "../components/FooterDelphus";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';

const swal = withReactContent(Swal);

const RegChamado_Situacao = () => {

    const [data, setData] = useState({});
    const [updateType, setUpdateType] = useState(false);
    const [enableEdit, setEnableEdit] = useState('N');
    const [showModal, setShowModal] = useState(false);
    const [cor, setCor] = useState('#FFFFFF')

    useEffect(() => {
        const fetchData = async () => {
            let tela = 'Situação Chamados'
            let consulta = await api.getAcessos(tela, 'Consulta')
            if (consulta !== 'S') {
                swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
                    .then((result) => {
                        if (result.value) {
                            window.history.back()
                        }
                    })
            } else {
                swal.fire({
                    html: <FrLoading text="Carregando..." />,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });

                const urlParams = new URLSearchParams(window.location.search);
                let query = paramsToObject(urlParams);

                if (query.codigo > 0) {
                    setUpdateType(true);
                    api.get('/chamado_situacao?pk_csi=' + query.codigo).then(r => {
                        if (r.data[0]) {
                            console.log(r.data[0]);
                            setData(r.data[0]);
                            if(r.data[0].cor_csi) setCor(r.data[0].cor_csi);
                            swal.close();
                        } else {
                            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning').then((result) => {
                                window.history.back();
                            });
                        }
                    });
                } else swal.close();
            }
        }
        fetchData();
    }, []);

    const consistData = (data) => {
        let required = [
        ];

        let errors = [];

        required.forEach((curr, index) => {
            let fieldName = curr;
            if (!data[curr]) return errors.push(fieldName);
        });

        return errors;
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const auxValues = { ...data };
                let errors = consistData(auxValues);
                console.log(errors.length);
                if (errors.length === 0) {
                    if (updateType) {
                        api.post('/chamado_situacao/edit', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Alteraddo',
                                    text: "O registro foi alterado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back();
                                    }
                                });
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi alterado!",
                                    icon: 'warning',
                                })
                            }
                        });
                    } else {
                        api.post('/chamado_situacao/add', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Adicionado',
                                    text: "O registro foi adicionado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back()
                                    }
                                });
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi adicionado!",
                                    icon: 'warning',
                                });
                            }
                        });
                    }
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "Verifique o(s) campo(s) " + errors.join(','),
                        icon: "warning"
                    });
                }
            }
        });
    }

    const handleCancel = (e) => {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                window.history.back();
            }
        });
    }

    const handleChange = (e) => {
        const auxValues = { ...data };
        switch (e.target.name) {
            case 'ativo':
                auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
                break
            default: auxValues[e.target.name] = e.target.value;
        }

        setData(auxValues);
    }

    const openColorModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    }

    const confirmaModal = (e) => {
        e.preventDefault();
        let auxValues = {... data}
        auxValues.cor_csi = cor;
        setData(auxValues);
        setShowModal(false);
    }

    const mudaCor = (color) =>{
        // e.preventDefault();
        console.log(color)
        setCor(color.hex);
    }
    
    const escondeModal = (e) => {
        e.preventDefault();
        setShowModal(false);
    }

    return (
        <div className="defaultReg">
            <div hidden={!showModal}>
                <Dialog aria-labelledby="simple-dialog-title" open={showModal} maxWidth={'lg'}>
                    <SketchPicker
                        color={cor}
                        onChange={mudaCor}
                    />
                    <div className="regButtonsDiv" style={{ padding: '10px' }}>
                        <button onClick={confirmaModal}> Confirmar </button>
                        <button onClick={escondeModal}> Sair </button>
                    </div>
                </Dialog>                
            </div>
            <NavBar />
            <div className="defaultRegContent">
                <div className="defaultHeader">
                    <h3>{updateType ? 'Edição' : 'Inclusão'} de Situação</h3>
                    <UserOptions />
                </div>
                <br />
                <form id="regForm" style={{display: 'flex', flexDirection: 'row'}} onSubmit={handleSubmit}>

                    <FrTextInput
                        value={data.descricao || ''}
                        style={{ width: 280 }}
                        name="descricao"
                        id="descricao"
                        color="#9c9595"
                        label="Descrição"
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                    />

                    <FrTextInput
                        value={data.descricao_externa || ''}
                        style={{ width: 280 }}
                        name="descricao_externa"
                        id="descricao_externa"
                        color="#9c9595"
                        label="Descrição Externa"
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name='ativo'
                                id='ativo'
                                size='small'
                                color="primary"
                                value={data.ativo || ''}
                                checked={data.ativo === 'S'}
                                onChange={handleChange}
                                style={{ marginLeft: '15px'}}
                            />
                        }
                        label={'Ativo'}
                    />

                    <FrTextInput
                        value={data.cor_csi || '#FFFFFF'}
                        style={{ width: 90 }}
                        name="cor_csi"
                        id="cor_csi"
                        color="#9c9595"
                        label="Cor"
                        variant="outlined"
                        size="small"
                        readOnly={true}
                        onChange={handleChange}
                        onClick={openColorModal}
                    />
                    <div style={{ backgroundColor: (data.cor_csi || '#FFFFFF'), border: '1px solid black', width: '25px', height: '25px', borderRadius: '180px', marginTop: '2px'}}/>
                    
                    


                </form>
                <div className="regButtonsDiv">
                    <button onClick={handleSubmit}>Salvar</button>
                    <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
                </div>
                <FooterDelphus />
            </div>
        </div>
    );
}

export default RegChamado_Situacao;