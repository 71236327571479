import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'

import { ic_work } from 'react-icons-kit/md/ic_work'
import { user } from 'react-icons-kit/icomoon/user'
import { ic_view_module } from 'react-icons-kit/md/ic_view_module'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Tooltip from '@material-ui/core/Tooltip';
import UserOptions from '../components/UserOptions'
import './styles/Home.css';
import FooterDelphus from '../components/FooterDelphus';
import api from '../services/api'


function Home() {
  const [notifications, setNotifications] = useState([])
  const [open, setOpen] = useState(false)
  const [auth, setAuth] = useState(JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0, fk_gpu: 0 })));

  useEffect(() => {
    api.get('/controle?notificacoes=true').then(r => {
      setNotifications(r.data)
      setOpen(r.data.length > 0)
    })
  }, [])

  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false)
  }



  return (
    <div className="Home">
      {auth.fk_gpu <= 2 ? <NavBar /> : <></>}

      <div className="HomeContent">
        <div className="defaultHeader">
          <h3>Seja bem vindo(a), <strong>{auth.name}</strong>!</h3>
          <UserOptions />
        </div>
        <br />

        <div className="atalhos">

          <div hidden={auth.fk_gpu >= 2} className='botaoHome'>
            <Tooltip title="Segure Ctrl para abrir em nova aba">
              <Nav.Link href="/suporte/clientes" className="atalho">
                <Icon icon={user} size={40} />
                <strong>{'Clientes'}</strong>
              </Nav.Link>
            </Tooltip>
          </div>

          <div className='botaoHome'>
            <Tooltip title="Segure Ctrl para abrir em nova aba">
              <Nav.Link href="/suporte/chamados?first_acc=true" className="atalho">
                <Icon icon={ic_work} size={40} />
                <strong>{'Chamados'}</strong>
              </Nav.Link>
            </Tooltip>
          </div>

          <div hidden={auth.fk_gpu >= 2} className='botaoHome'>
            <Tooltip title="Segure Ctrl para abrir em nova aba">
              <Nav.Link href="/suporte/modulos" className="atalho">
                <Icon icon={ic_view_module} size={40} />
                <strong>{'Modulos'}</strong>
              </Nav.Link>
            </Tooltip>
          </div>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Home;
