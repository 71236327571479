import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swal = withReactContent(Swal)

class ProtectedRoute extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            auth: JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0, fk_gpu: 0 }))
        };
    }

    // componentWillMount() {
    //     let strAuth = localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0 })
    //     let auth = JSON.parse(strAuth) 

    //     if (auth.last_activity) {
    //         let inactivityTime = Math.abs((new Date(auth.last_activity) - new Date()))
    //         let inactivityTimeInMinutes = inactivityTime/60000
    //         console.log(inactivityTimeInMinutes)
    //         if (inactivityTimeInMinutes >= 30) {
    //             localStorage['authDelphus'] = JSON.stringify({ authenticated: false, user: 0 })
    //             this.setState({ auth: { authenticated: false, user: 0 } })
    //             swal.fire({
    //                 title: 'Desconectado por inatividade',
    //                 text: "Sessão expirada. Por favor, faça o login novamente.",
    //                 icon: 'warning',
    //                 confirmButtonColor: '#3085d6',
    //                 confirmButtonText: 'Ok',
    //                 allowOutsideClick: false
    //             })
    //         } else {
    //             auth.last_activity = new Date()
    //             localStorage['authDelphus'] =  JSON.stringify(auth)
    //         }
    //     } else {
    //         this.setState({ auth: { authenticated: false, user: 0 } })
    //         localStorage['authDelphus'] =  JSON.stringify({ authenticated: false, user: 0 })
    //         window.location.href = '/suporte/login'
    //     }
    // }

    render() {
        const { component: Component, ...props } = this.props
        return (
            <Route
                {...props}
                render={props => (
                    ((this.state.auth.authenticated)) ?
                        <Component {...props} /> :
                        <Redirect to='/suporte/login' />
                )}
            />
        )
    }
}

export default ProtectedRoute;