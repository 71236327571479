import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    color: 'white',
    maxHeight: 300,
    overflowY: 'auto',
    backgroundColor: '#45688d',
    '& .MuiListItemIcon-root': {
      minWidth: '0',
      height: 10
    },
  },


}));

const FrCheckBox = withStyles({
  root: {
    color: 'white',
    minWidth: '0',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxList(props) {
  const classes = useStyles();

  const handleToggle = (value) => () => {
    const currentIndex = props.checked.indexOf(value);
    const newChecked = [...props.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    console.log(newChecked)
    props.setChecked(newChecked)
  };

  return (

    <List className={classes.root}  >
      {props.data.map((item) => {
        const labelId = `checkbox-list-label-${item[props.pk]}`;

        return (
          <ListItem key={item[props.pk]} role={undefined} button onClick={handleToggle(item[props.pk])}>
            <ListItemText id={labelId} primary={item[props.text]} />
            <ListItemIcon>
              <FrCheckBox
                edge="end"
                checked={props.checked.indexOf(item[props.pk]) !== -1}
                tabIndex={-1}
                disableRipple

              />
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
}