import Inicio from '../pages/Inicio';
import Login from '../pages/Login';
import Grupo_Usuarios from '../pages/Grupo_Usuarios';
import RegGrupo_Usuarios from '../pages/RegGrupo_Usuarios';
import Usuarios from '../pages/Usuarios';
import RegUsuarios from '../pages/RegUsuarios';
import ChangePw from '../pages/ChangePw';
import ForgotPw from '../pages/ForgotPw';
import Chamados from '../pages/Chamados';
import Cidades from '../pages/Cidade';
import Estados from '../pages/Estados';
import RegCliente from '../pages/RegCliente';
import RegCidades from '../pages/RegCidades';
import RegEstado from '../pages/RegEstado';
import Cliente from '../pages/Cliente';
import RelChamados from '../pages/RelChamados';
import Modulos from '../pages/Modulos';
import RegModulos from '../pages/RegModulos';
import Modulo_Situacao from '../pages/Modulo_Situacao';
import RegModulo_Situacao from '../pages/RegModulo_Situacao';
import Tipo_Chamado from '../pages/Tipo_Chamado';
import RegTipo_Chamado from '../pages/RegTipo_Chamado';
import Chamado_Situacao from '../pages/Chamado_Situacao';
import RegChamado_Situacao from '../pages/RegChamado_Situacao';
import Tipo_Movimentacao from '../pages/Tipo_Movimentacao';
import RegTipo_Movimentacao from '../pages/RegTipo_Movimentacao';
import Home from '../pages/Home';

export const routes = [

    {
        path: '/chamados',
        component: Chamados,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/inicio',
        component: Inicio,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/usuarios',
        component: Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Usuários'
    },
    {
        path: '/usuarios/registro',
        component: RegUsuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/grupo_usuarios',
        component: Grupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupos de Usuários'
    },
    {
        path: '/grupo_usuarios/registro',
        component: RegGrupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/cidades',
        component: Cidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cidades'
    },
    {
        path: '/cidades/registro',
        component: RegCidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/estados',
        component: Estados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Estados'
    },
    {
        path: '/estados/registro',
        component: RegEstado,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true

    },
    {
        path: '/clientes/registro',
        component: RegCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/clientes',
        component: Cliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Clientes'
    },
    {
        path: '/modulos',
        component: Modulos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Módulos'
    },
    {
        path: '/modulos/registro',
        component: RegModulos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/modulos_situacao',
        component: Modulo_Situacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Módulos'
    },
    {
        path: '/modulos_situacao/registro',
        component: RegModulo_Situacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_chamado',
        component: Tipo_Chamado,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos Chamados'
    },
    {
        path: '/tipo_chamado/registro',
        component: RegTipo_Chamado,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/chamado_situacao',
        component: Chamado_Situacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Chamados'
    },
    {
        path: '/chamado_situacao/registro',
        component: RegChamado_Situacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_movimentacao',
        component: Tipo_Movimentacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo Movimentações'
    },
    {
        path: '/tipo_movimentacao/registro',
        component: RegTipo_Movimentacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/home',
        component: Home,
        protected: false,
        exact: true,
        checkPermission: false,
        register: true
    },
    {
        path: '/rel_chamados',
        component: RelChamados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Relatório Chamados'
    },
];

export const fallbackRoute = Inicio;

