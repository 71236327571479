import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { Icon } from 'react-icons-kit';
import { Link } from 'react-router-dom';
import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_search } from 'react-icons-kit/md/ic_search';
import { ic_delete } from 'react-icons-kit/md/ic_delete';


import NavBar from "../components/NavBar";
import FrLoading from '../components/FrLoading';
import FrTextInput from '../components/FrTextInput';
import UserOptions from '../components/UserOptions';
import FooterDelphus from "../components/FooterDelphus";
import FrTable from "../components/FrTableReg";

import api from "../services/api";
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions';

const swal = withReactContent(Swal);

function Estados(props) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const [filtered, setFiltered] = useState(false);
  const [enableAdd, setenableAdd] = useState('S');
  const [enableEdit, setenableEdit] = useState('N');
  const [enableDel, setenableDel] = useState('N');

  const columns = [
    { id: 'nome', numeric: false, label: 'Estado', align: 'left', minWidth: 300 },

  ];

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Estados'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        });

        setenableAdd(await api.getAcessos(tela, 'Inclusao'));
        setenableEdit(await api.getAcessos(tela, 'Alteracao'));
        setenableDel(await api.getAcessos(tela, 'Exclusao'));

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams);

        setFilter(query);
        populateForm(document.getElementById('searchForm'), query);

        swal.close();

        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event);
        }
      }
    }
    fetchData();
  }, []);

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true;
      setFiltered(true);
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/suporte/estados?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/estados', { params: queryObject }).then(r => {
        setData(r.data);
        swal.close();
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning');
    }
  }

  const clearForm = (e) => {
    e.preventDefault();
    console.log("limpa");
    window.history.replaceState({ filtered: false }, 'filter', '/suporte/estados');
    document.getElementById('searchForm').reset();
    setFilter({});
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues);
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  }

  return (
    <div className="Manut">
      {props.frame ? <div /> : <NavBar />}
      <div className={"ManutContent" + (props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Estados</h3>
          {props.frame ? <div /> : <UserOptions />}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.nome || ''}
                style={{ width: 330 }}
                name="nome"
                id="nome"
                color="#9c9595"
                label="Estado"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/suporte/estados/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')}>
          <FrTable regName="estados" frame={props.frame} columns={columns} searched={filtered} codeName="pk_est" page={'Estados'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus />}
      </div>
    </div>
  );
}

export default Estados;