import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";

import NavBar from "../components/NavBar";
import FrTextInput from '../components/FrTextInput';
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import Checkbox from '@material-ui/core/Checkbox';

import api from '../services/api';
import { conferePermissao, paramsToObject } from "../utils/functions";

import './styles/DefaultReg.css';
import FrSelect from '../components/FrSelect'
import FooterDelphus from "../components/FooterDelphus";
import { CheckBox } from "@material-ui/icons";

const swal = withReactContent(Swal);

const RegModulos = () => {

    const [data, setData] = useState({});
    const [updateType, setUpdateType] = useState(false);
    const [enableEdit, setEnableEdit] = useState('N');
    const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
    const [comboMsi, setComboMsi] = useState([{ value: '', display: '' }])
    const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }])
    const [comboAtivo, setComboAtivo] = useState([
        { display: 'Sim', value: 'S' },
        { display: 'Não', value: 'N' }
    ])

    useEffect(() => {
        const fetchData = async () => {
            let tela = 'Módulos'
            let consulta = await api.getAcessos(tela, 'Consulta')
            if (consulta !== 'S') {
                swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
                    .then((result) => {
                        if (result.value) {
                            window.history.back()
                        }
                    })
            } else {
                swal.fire({
                    html: <FrLoading text="Carregando..." />,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });
                setComboClientes(await api.getComboData('clientes'))
                setComboMsi(await api.getComboData('modulo_situacao'))
                api.get('/usuarios/comboReq?interno=S').then(r => {
                    setComboUsu(r.data)
                })
                

                const urlParams = new URLSearchParams(window.location.search);
                let query = paramsToObject(urlParams);

                if (query.codigo > 0) {
                    setUpdateType(true);
                    api.get('/modulos?pk_mod=' + query.codigo).then(r => {
                        if (r.data[0]) {
                            setData(r.data[0]);
                            swal.close();
                        } else {
                            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning').then((result) => {
                                window.history.back();
                            });
                        }
                    });
                } else swal.close();
            }
        }
        fetchData();
    }, []);

    const consistData = (data) => {
        let required = [
        ];

        let errors = [];

        required.forEach((curr, index) => {
            let fieldName = curr;
            if (!data[curr]) return errors.push(fieldName);
        });

        return errors;
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const auxValues = { ...data };
                let errors = consistData(auxValues);
                console.log(errors.length);
                if (errors.length === 0) {
                    if (updateType) {
                        api.post('/modulos/edit', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Alteraddo',
                                    text: "O registro foi alterado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back();
                                    }
                                });
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi alterado!",
                                    icon: 'warning',
                                })
                            }
                        });
                    } else {
                        api.post('/modulos/add', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Adicionado',
                                    text: "O registro foi adicionado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back()
                                    }
                                });
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi adicionado!",
                                    icon: 'warning',
                                });
                            }
                        });
                    }
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "Verifique o(s) campo(s) " + errors.join(','),
                        icon: "warning"
                    });
                }
            }
        });
    }

    const handleCancel = (e) => {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                window.history.back();
            }
        });
    }

    const handleChange = (e) => {
        const auxValues = { ...data };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }

        setData(auxValues);
    }

    return (
        <div className="defaultReg">
            <NavBar />
            <div className="defaultRegContent">
                <div className="defaultHeader">
                    <h3>{updateType ? 'Edição' : 'Inclusão'} de Módulo</h3>
                    <UserOptions />
                </div>
                <br />
                <form id="regForm" onSubmit={handleSubmit}>

                    <FrTextInput
                        maxLength='50'
                        value={data.descricao || ''}
                        onChange={handleChange}
                        name='descricao'
                        id='descricao'
                        color="#49573B"
                        label='Descrição'
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 330 }}
                    />

                    <FrTextInput
                        maxLength='50'
                        value={data.detalhamento || ''}
                        onChange={handleChange}
                        name='detalhamento'
                        id='detalhamento'
                        color="#49573B"
                        label='Detalhamento'
                        variant="outlined"
                        size="small"
                        // required
                        style={{ width: 330 }}
                    />

                    <FrSelect
                        value={data.fk_msi || ''}
                        style={{ width: 170 }}
                        name="fk_msi"
                        id="fk_msi"
                        label='Situação'
                        data={comboMsi}
                        onChange={handleChange}

                    />
                    <FrSelect
                        value={data.fk_cli || ''}
                        style={{ width: 170 }}
                        name="fk_cli"
                        id="fk_cli"
                        label='Cliente'
                        data={comboClientes}
                        onChange={handleChange}

                    />
                    <FrSelect
                        value={data.fk_usu_responsavel || ''}
                        style={{ width: 170 }}
                        name="fk_usu_responsavel"
                        id="fk_usu_responsavel"
                        label='Responsável'
                        data={comboUsu}
                        onChange={handleChange}

                    />
                    <FrSelect
                        value={data.ativo || ''}
                        style={{ width: 100 }}
                        name="ativo"
                        id="ativo"
                        label="Ativo"
                        data={[{value: 'S', display: 'Sim'},{value: 'N', display: 'Não'}]}
                        onChange={handleChange}
                    />
                </form>
                <div className="regButtonsDiv">
                    <button onClick={handleSubmit}>Salvar</button>
                    <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
                </div>
                <FooterDelphus />
            </div>
        </div>
    );
}

export default RegModulos;