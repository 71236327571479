import React, {useState} from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { routes, fallbackRoute } from './routes'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './App.css';


const swal = withReactContent(Swal);

function App() {

	const [auth, setAuth] = useState(JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0 })));

	const routeComponents = routes.map((route, key) => {
		if (route.protected) {
			return <ProtectedRoute path={'/suporte'+route.path} exact={route.exact} component={route.component}/>
		} else {
			return <Route path={'/suporte'+route.path} exact={route.exact} component={route.component}/>
		}
	});

	const timerInatividade = (e) => {

		// console.log('Timer Iniciado')
		if (auth.inativo != true && auth.user > 0){
			let tempo;
			let control = 0;

			const desconectarInativo = () => {
				localStorage['authDelphus'] = JSON.stringify({ authenticated: false, user: 0, inativo: true })
				window.history.replaceState({ filtered: false }, 'filter','/suporte/login');
				window.location.reload();
			}

			const resetTimer = () => {
				// console.log('Reset')
				control = 0;

				clearTimeout(tempo);

				const time_out = auth.fk_gpu > 2 ? 600000 : 1800000;

				tempo = setTimeout(desconectarInativo, time_out);
			}

			const mostraTempo = () => {			
				console.log(control);

				control++;
			}

			// setInterval(mostraTempo, 1000)

			window.onload = resetTimer;

			document.onmousemove = resetTimer;

			document.onkeydown = resetTimer;
		}
	}
	

	return (
		<div className="App">
			{timerInatividade()}
			<Router>
				<Switch>
					{routeComponents}
					<ProtectedRoute component={fallbackRoute} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
