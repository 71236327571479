import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import api from '../services/api';
import { conferePermissao, paramsToObject } from '../utils/functions';

import NavBar from '../components/NavBar';
import UserOptions from '../components/UserOptions';
import FrTextInput from '../components/FrTextInput';
import FrSelect from '../components/FrSelect';
import FrLoading from '../components/FrLoading';

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal);

function Cidades() {
  const [updateType, setUpdateType] = useState(false);
  const [data, setData] = useState({});
  const [comboEstados, setComboEstados] = useState([{ value: '', display: '' }]);
  const [enableEdit, setEnableEdit] = useState('N');

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Cidades'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        });

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams);

        if (query.codigo > 0) {
          setEnableEdit(await api.getAcessos('Acessos', 'Alteracao'));
        } else {
          setEnableEdit(await api.getAcessos('Acessos', 'Inclusao'));
        }

        setComboEstados(await api.getComboData('estados'));

        if (query.codigo > 0) {
          setUpdateType(true);
          api.get('/cidades?pk_cid=' + query.codigo).then(r => {
            if (r.data[0]) {
              console.log(r.data[0]);
              setData(r.data[0]);
              swal.close();
            } else {
              swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                .then((result) => {
                  if (result.value) {
                    window.history.back();
                  }
                });
            }
          });
        } else {
          swal.close();
        }
      }
    }
    fetchData();
  }, []);

  const consistData = (data) => {
    let required = [
      'nome',
      'fk_est',
      'codigo_ibge'
    ];

    let errors = [];

    required.forEach((curr, index) => {
      let fieldName = curr;
      if (curr === 'fk_est') fieldName = 'Estado';
      if (!data[curr]) return errors.push(fieldName);
    });

    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/cidades/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/cidades/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Cidade</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='100'
            value={data.nome || ''}
            onChange={handleChange}
            name='nome'
            id='nome'
            color='#9c9595'
            label='Nome da cidade'
            variant='outlined'
            size='small'
            required
            style={{ width: 700 }}
          />

          <FrSelect
            value={data.fk_est || ''}
            onChange={handleChange}
            name="fk_est"
            id="fk_est"
            label='Estado'
            data={comboEstados}
            style={{ width: 250 }}
          />

          <FrTextInput
            maxLength='10'
            value={data.codigo_ibge || ''}
            onChange={handleChange}
            name="codigo_ibge"
            id="codigo_ibge"
            color="#9c9595"
            label="Código IBGE"
            variant="outlined"
            size="small"
            required
            style={{ width: 130 }}
          />

        </form>


        <div className="regButtonsDiv">
          <button onClick={handleSubmit}>Salvar</button>
          <button onClick={handleCancel}>{(enableEdit === 'S') ? 'Cancelar' : 'Fechar'}</button>
        </div>

        <FooterDelphus />

      </div>
    </div>
  );

}

export default Cidades;