import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrTextInput from '../components/FrTextInput'
import api from '../services/api';
import $ from 'jquery';
import UserOptions from '../components/UserOptions';
import FooterDelphus from "../components/FooterDelphus";
import FrSelect from "../components/FrSelect";
import FrTable from "../components/FrTable";

import { paramsToObject } from "../utils/functions";
import './styles/Login.css';
import { cross } from 'react-icons-kit/icomoon/cross';
import delphus from '../imgs/delphus_full_white.png';
import { Icon } from "react-icons-kit";
import { search } from 'react-icons-kit/icomoon/search';
import { download2 } from 'react-icons-kit/icomoon/download2';
import FrDatePicker from '../components/FrDatePicker';
import download from 'downloadjs';
const moment = require("moment");

const swal = withReactContent(Swal)

function Inicio() {

    const [auth, setAuth] = useState(JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0, fk_gpu: 0 })));
    const [data, setData] = useState({})
    const [checked, setChecked] = React.useState(false);
    const [comboMod, setComboMod] = useState([{ value: '', display: '' }]);
    const [updateType, setUpdateType] = useState(false);
    const [comboCli, setComboCli] = useState([{ value: '', display: '' }]);
    const [comboCsi, setComboCsi] = useState([{ value: '', display: '' }]);
    const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }]);
    const [comboUsuAtual, setComboUsuAtual] = useState([{ value: '', display: '' }]);
    const [comboUsuResp, setComboUsuResp] = useState([{ value: '', display: '' }]);
    const [comboPublico, setComboPublico] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }]);
    const [codCha, setCodCha] = useState(paramsToObject(new URLSearchParams(window.location.search)).codigo)
    const [comboUsuSol, setComboUsuSol] = useState([{ value: '', display: '' }]);
    const [comboTch, setComboTch] = useState([{ value: '', display: '' }]);
    const [usuIsAdm, setUsuIsAdm] = useState('N')
    const editaCamposAdm = (usuIsAdm == 'S')

    const [dataMov, setDataMov] = useState([{ value: '', display: '' }]);
    const [fromManut, setFromManut] = useState(false);

    const columns = [
        { id: 'data', hidden: true},
        { id: 'formatdatetime', numeric: false, label: 'Data Mov.', align: 'left', minWidth: 150, width: 60, orderBy: 'data' },
        { id: 'nomeusu', numeric: false, label: 'Usuário', align: 'left', minWidth: 100 },
        { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 150 },
        { id: 'nomeusuatu', numeric: false, label: 'Atribuído a', align: 'left', minWidth: 150 },
        { id: 'arquivos', numeric: false, label: 'Anexos', align: 'left', minWidth: 150}

    ]


    useEffect(() => {
        const fetchData = async () => {
            setComboCli(await api.getComboData('clientes'));
            setComboCsi(await api.getComboData('chamado_situacao'));
            let usuarios = await api.getComboData('usuarios')
            setComboUsu(usuarios);
            setComboUsuAtual(usuarios);
            await api.get('/usuarios/comboReq?interno=S').then(r => {
                // console.log(r.data);
                setComboUsuResp(r.data);
            })
            
            
            setComboUsuSol(usuarios)
            setComboTch(await api.getComboData('tipo_chamado'))
            if(auth.usu) setUsuIsAdm(await api.usuIsAdm(auth.usu))


            const urlParams = new URLSearchParams(window.location.search);
            let query = paramsToObject(urlParams);
            if(query.manut) setFromManut(true);
            console.log(query)
            if (query.codigo > 0) {
                setUpdateType(true);
                console.log('oieeeeee')
                api.get('/chamados?pk_cha=' + query.codigo).then(r => {
                    console.log(r.data)
                    if (r.data[0]) {
                        r.data[0].data_abertura = moment(r.data[0].data_abertura).format('YYYY-MM-DDTHH:mm')
                        r.data[0].data_previsao = moment(r.data[0].data_previsao).format('YYYY-MM-DD')
                        r.data[0].tempo_estimado = r.data[0].tempo_estimado?.replace(/^(\d{2})(\d{2})/,"$1:$2").substring(0,5)
                        console.log(r.data[0])
                        if (r.data[0].arquivos) {

                            let arquivos = r.data[0].arquivos.split(',')
                            arquivos.map(async (item, index) => {
                                let arq;

                                console.log(arq)
                                let pos = query.codigo.length + 26

                                let nomeArq = item.substring(pos, 100);

                                await api.post('chamados/downloadAnexo?arquivo='+nomeArq+'&fk_cha='+r.data[0].pk_cha, {}, {responseType: 'blob'}).then((response) => {
                                    console.log(response)
                                    var blob = new Blob([response.data], {
                                        type: response.data.type
                                    });
                                    console.log(blob)
                                    arq = blob;
                                    console.log(arq)
                                })

                                if (index == 0){
                                    r.data[0].arq1 = blobToFile(arq, nomeArq)
                                    r.data[0].arquivo1 = nomeArq
                                }
                                if (index == 1){
                                    r.data[0].arq2 = blobToFile(arq, nomeArq)
                                    r.data[0].arquivo2 = nomeArq
                                }
                                if (index == 2){
                                    r.data[0].arq3 = blobToFile(arq, nomeArq)
                                    r.data[0].arquivo3 = nomeArq
                                }
                                if (index == 3){
                                    r.data[0].arq4 = blobToFile(arq, nomeArq)
                                    r.data[0].arquivo4 = nomeArq
                                }
                                if (index == 4){
                                    r.data[0].arq5 = blobToFile(arq, nomeArq)
                                    r.data[0].arquivo5 = nomeArq
                                }
                                if (index == 5){
                                    r.data[0].arq6 = blobToFile(arq, nomeArq)
                                    r.data[0].arquivo6 = nomeArq
                                }
                            })

                        }
                        setData(r.data[0]);

                        api.get('/chamados/movimentacoes?pk_cha=' + query.codigo + '&fk_usu=' + auth.user).then(r => {
                            // console.log(r.data)
                            setDataMov(r.data)
                        })
                    }
                });

            }
            else if (auth.fk_gpu > 2) {
                const auxValues = { ...data };
                auxValues.fk_cli = auth.fk_cli
                auxValues.publico = 'S'
                setData(auxValues)
            }
            else if (auth.fk_gpu <= 2) {
                const auxValues = { ...data };
                auxValues.fk_usu_abertura = auth.user
                auxValues.data_abertura = moment(new Date()).format('yyyy-MM-DDTHH:mm')
                auxValues.prioridade = 99;
                auxValues.fk_csi = 1;
                auxValues.publico = 'N';
                setData(auxValues)
            }
            return () => {
                auth.lastQuery = '';
                localStorage['authDelphus'] = JSON.stringify(auth);
            }

        }

        fetchData();
        return
    }, []);

    const handleChange = (e) => {

        const auxValues = { ...data };
        switch (e.target.name) {
            case 'fk_tch':
                if(e.target.value){
                    auxValues['fk_tch'] = e.target.value;
                } else {
                    auxValues['fk_tch'] = null;
                }
            case 'tempo_estimado' :
                auxValues[e.target.name] = e.target.value.replace(/^(\d{2})(\d)/,"$1:$2")
            case 'check':
                setChecked(e.target.checked);
                break;

            default: auxValues[e.target.name] = e.target.value;
        }
        console.log(auxValues)
        setData(auxValues);


    };

    const blobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName
        return theBlob
    }

    const handleDelete = (n) => {
        swal.fire({
            title: 'ATENÇÃO',
            text: "Confirma excluir arquivo?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {

                const auxValues = { ...data };
                if (n == 1) {
                    auxValues.arquivo1 = ''
                    auxValues.arq1 = ''
                } if (n == 2) {
                    auxValues.arquivo2 = ''
                    auxValues.arq2 = ''
                } if (n == 3) {
                    auxValues.arquivo3 = ''
                    auxValues.arq3 = ''
                } if (n == 4) {
                    auxValues.arquivo4 = ''
                    auxValues.arq4 = ''
                } if (n == 5) {
                    auxValues.arquivo5 = ''
                    auxValues.arq5 = ''
                } if (n == 6) {
                    auxValues.arquivo6 = ''
                    auxValues.arq6 = ''
                }
                setData(auxValues);
            }
        })

    }


    const handleView = (n) => {
        // let path = api.backendUrl + '/static/upload/chamados/' + data.pk_cha + '/'
        let arq = '';
        if (n == 1)
            arq = data.arquivo1;
        if (n == 2)
            arq = data.arquivo2;
        if (n == 3)
            arq = data.arquivo3;
        if (n == 4)
            arq = data.arquivo4;
        if (n == 5)
            arq = data.arquivo5;
        if (n == 6)
            arq = data.arquivo6;

        console.log(arq, data.pk_cha)

        api.post(`/chamados/visualizarArquivo?arquivo=${arq}&fk_cha=${data.pk_cha}`, {}, {responseType: 'blob'}).then(r => {
            console.log(r);
            var blob = new Blob([r.data], {
                type: r.data.type
            });
            console.log(blob)
            $("#img1").attr("src", window.URL.createObjectURL(blob));
            window.open(window.URL.createObjectURL(blob))
        });
    }

    const handleDownload = (n) => {
        let arq;
        let cham = data.pk_cha;
        //console.log(arq, cham)

        if (n == 1)
            arq = data.arquivo1;
        if (n == 2)
            arq = data.arquivo2;
        if (n == 3)
            arq = data.arquivo3;
        if (n == 4)
            arq = data.arquivo4;
        if (n == 5)
            arq = data.arquivo5;
        if (n == 6)
            arq = data.arquivo6;
        //console.log(arq)
        // let queryObject = {pk_cha: data.pk_cha, arquivo: arq};
        // api.get('/chamados/downloadAnexo', {params: queryObject});
        // const url = api.backendUrl + `/api/chamados/downloadAnexo?fk_cha=${cham}&arquivo=${arq}`;
        // var link = document.createElement("a");
        // link.href = url;
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        console.log(arq)
        api.post('chamados/downloadAnexo?arquivo='+arq+'&fk_cha='+data.pk_cha, {}, {responseType: 'blob'}).then((response) => {
            if(response.data.type == 'application/json'){
              swal.fire({
                  title: 'Arquivo Não Encontrado',
                  text: "Arquivo não foi encontrado no servidor!",
                  icon: 'error',
              })
            } else {
              console.log(response, data.arquivo)
              console.log(response.data)
                var blob = new Blob([response.data], {
                  type: response.data.type
                });
                console.log(blob)
                download(blob, arq)
            }
        })
    }

    function consistData(data) {
        let required = [
            'detalhamento',
            'titulo',
            'fk_mod'
        ]

        let errors = []

        required.forEach((curr, index) => {
            let fieldName = curr
            if (fieldName == 'fk_mod')
                fieldName = 'Módulo'
            if (!data[curr]) return errors.push(fieldName)
        })

        // if (!checked)
        //     errors.push('Marque que vc não é um robô!')
        return errors
    }


    function handleSubmit(e) {
        e.preventDefault();
        if (!data.detalhamento) data.detalhamento = '';
        console.log(data)
        if (((data.detalhamento.toLowerCase().indexOf('anexo')) > 0) && (!data.arquivo1) && (!data.arquivo2) && (!data.arquivo3) && (!data.arquivo4) && (!data.arquivo5) && (!data.arquivo6)) {
            swal.fire({
                title: 'ATENÇÃO',
                text: "Tem certeza de não ter esquecido algum anexo?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {

                if (result.value)
                    confirma()

            })
        } else
            confirma()
    }

    const confirma = (e) => {


        swal.fire({
            title: 'ATENÇÃO',
            text: "Confirma solicitação?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const auxValues = { ...data };
                if (auth.fk_gpu > 2) auxValues.fk_usu_solicitante = auth.user;

                console.log(auxValues)
                let errors = consistData(auxValues)

                let formData = new FormData();
                formData.append('titulo', auxValues.titulo)
                formData.append('detalhamento', auxValues.detalhamento)
                formData.append('prioridade', auxValues.prioridade)
                formData.append('fk_usu_atual', auxValues.fk_usu_atual)
                formData.append('data_encerramento', auxValues.data_encerramento)
                formData.append('fk_csi', auxValues.fk_csi)

                formData.append('arquivo1', auxValues.arquivo1)
                formData.append('arquivo2', auxValues.arquivo2)
                formData.append('arquivo3', auxValues.arquivo3)
                formData.append('arquivo4', auxValues.arquivo4)
                formData.append('arquivo5', auxValues.arquivo5)
                formData.append('arquivo6', auxValues.arquivo6)

                formData.append('pk_usu', auth.user)
                formData.append('tempo_estimado', auxValues.tempo_estimado || null)
                formData.append('fk_mod', auxValues.fk_mod)
                formData.append('pk_cha', auxValues.pk_cha)
                formData.append('fk_usu_responsavel', auxValues.fk_usu_responsavel)
                formData.append('fk_usu_solicitante', auxValues.fk_usu_solicitante ? auxValues.fk_usu_solicitante : null)
                formData.append('publico', auxValues.publico)
                formData.append('data_previsao', auxValues.data_previsao)
                formData.append('data_abertura', auxValues.data_abertura || null)
                formData.append('fk_tch', auth.fk_gpu > 2 ? 1 : auxValues.fk_tch)



                if (data.arq1){
                    formData.append('file', data.arq1, data.arquivo1)
                }
                if (data.arq2){
                    formData.append('file', data.arq2, data.arquivo2)
                }
                if (data.arq3){
                    formData.append('file', data.arq3, data.arquivo3)
                }
                if (data.arq4){
                    formData.append('file', data.arq4, data.arquivo4)
                }
                if (data.arq5){
                    formData.append('file', data.arq5, data.arquivo5)
                }
                if (data.arq6){
                    formData.append('file', data.arq6, data.arquivo6)
                }

                console.log(data)

                if (errors.length === 0) {
                    let chamada = (updateType) ? '/chamados/edit' : '/chamados/add'
                    api.post(chamada, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(r => {
                        if (r.status === 200) {
                            let titulo = (updateType) ? 'Alterações gravadas com sucesso' : 'Suporte Aberto com sucesso'
                            let texto = (updateType) ? "Suporte alterado com sucesso!" : "O registro do seu suporte foi realizado com sucesso! As informações e o acompanhamento do suporte serão enviados para o seu email."

                            swal.fire({
                                title: titulo,
                                text: texto,
                                icon: 'success',
                            }).then((result) => {
                                if (result.value) {
                                    if (fromManut) {
                                        window.history.replaceState({ filtered: true }, 'filter','/suporte/chamados' + auth.lastQuery)
                                        window.location.reload();
                                        // window.history.back();
                                    } else {
                                        window.history.replaceState({ filtered: true }, 'filter','/suporte/chamados?first_acc=true');
                                        window.location.reload();
                                    }

                                }
                            })
                        } else {
                            swal.fire({
                                title: 'Erro!',
                                text: "Ocorreu um erro no lançamento do suporte!",
                                icon: 'warning',
                            })
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "Verifique o(s) campo(s) " + errors.join(','),
                        icon: 'warning',
                    })
                }
            }

        })
    }


    const handleSelectedFile = (e) => {

        let file = e.target.files[0];
        let campo = e.target.name;
        console.log(e.target.name, e.target.files[0]);
        let auxValues = { ...data }
        if (file) {
            if (campo == 'arquivo1') {
                auxValues.arq1 = file
                auxValues.arquivo1 = file.name
            }
            if (campo == 'arquivo2') {
                auxValues.arq2 = file
                auxValues.arquivo2 = file.name
            }
            if (campo == 'arquivo3') {
                auxValues.arq3 = file
                auxValues.arquivo3 = file.name
            }
            if (campo == 'arquivo4') {
                auxValues.arq4 = file
                auxValues.arquivo4 = file.name
            }
            if (campo == 'arquivo5') {
                auxValues.arq5 = file
                auxValues.arquivo5 = file.name
            }
            if (campo == 'arquivo6') {
                auxValues.arq6 = file
                auxValues.arquivo6 = file.name
            }

        } else {
            if (campo == 'arquivo1') {
                auxValues.arq1 = null
                auxValues.arquivo1 = null
            }
            if (campo == 'arquivo2') {
                auxValues.arq2 = null
                auxValues.arquivo2 = null
            }
            if (campo == 'arquivo3') {
                auxValues.arq3 = null
                auxValues.arquivo3 = null
            }
            if (campo == 'arquivo4') {
                auxValues.arq4 = null
                auxValues.arquivo4 = null
            }
            if (campo == 'arquivo5') {
                auxValues.arq5 = null
                auxValues.arquivo5 = null
            }
            if (campo == 'arquivo6') {
                auxValues.arq6 = null
                auxValues.arquivo6 = null
            }

        }

        console.log(auxValues)
        setData(auxValues)
    }



    const selectFile = (e, name) => {
        e.preventDefault();
        console.log(e.target.name, !name)
        if (!name) {
            if (!data.arquivo1)
                name = 'arquivo11'
            else
                if (!data.arquivo2)
                    name = 'arquivo21'
                else
                    if (!data.arquivo3)
                        name = 'arquivo31'
                    else
                        if (!data.arquivo4)
                            name = 'arquivo41'
                        else
                            if (!data.arquivo5)
                                name = 'arquivo51'
                            else
                                if (!data.arquivo6)
                                    name = 'arquivo61'
        }
        console.log(name, data.arquivo1)
        $(`#${name}`).click()      
    }

    $(`#arquivo1`).on("click", (e)=>{
        console.log(e.target)
    })


    function handleCancel(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                //window.location.href = '/suporte/chamados?fk_csi=1'
                if (fromManut) {
                    window.history.replaceState({ filtered: true }, 'filter','/suporte/chamados' + auth.lastQuery)
                    window.location.reload();
                    // window.history.back();
                } else {
                    window.history.replaceState({ filtered: true }, 'filter','/suporte/chamados?first_acc=true');
                    window.location.reload();
                }                

            }
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            setComboMod(await api.getComboData('modulos', data.fk_cli * -1));
            await api.get('/usuarios/comboSol?fk_cli=' + data.fk_cli).then(r => {
                setComboUsuSol(r.data);
            });
        }

        fetchData();
    }, [data.fk_cli]);

    useEffect(() => {
        const fetchData = async () => {
            if ((!updateType)) {
                api.get('/modulos?pk_mod=' + data.fk_mod).then(r => {
                    if (r.data[0]) {
                        let fk_usu = r.data[0].fk_usu_responsavel

                        const auxValues = { ...data };
                        auxValues.fk_usu_responsavel = fk_usu
                        auxValues.fk_usu_atual = fk_usu
                        setData(auxValues)
                    }
                })
            }
        }

        fetchData();
    }, [data.fk_mod]);


    return (
        <div className="cabecalho">

            <div className="delphus">

                <img alt="delphus" src={delphus} title="delphus" className="logo_delphus" />
                <div className="ManutContent">

                    <div className="defaultHeader">
                        <h3>Sistema de Suporte</h3>
                        <UserOptions />
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <form id="regForm" onSubmit={handleSubmit} >

                            <FrSelect
                                value={data.fk_cli || ''}
                                onChange={handleChange}
                                name="fk_cli"
                                id="fk_cli"
                                label='Projeto'
                                data={comboCli}
                                style={{ width: 250 }}
                                disabled={auth.fk_gpu > 2 || updateType}
                            />


                            <FrSelect
                                value={data.fk_mod || ''}
                                onChange={handleChange}
                                name="fk_mod"
                                id="fk_mod"
                                label='Módulo do Sistema'
                                data={comboMod}
                                style={{ width: 250 }}
                                disabled={auth.fk_gpu > 2 && updateType}
                            />

                            <FrSelect
                                value={data.fk_tch || ''}
                                onChange={handleChange}
                                name="fk_tch"
                                id="fk_tch"
                                label='Tipo de Chamado'
                                data={comboTch}
                                style={{ width: 250 }}
                                disabled={auth.fk_gpu > 2 && updateType}
                                hidden={auth.fk_gpu > 2}
                            />

                            <br />
                            <FrDatePicker
                                value={(data.data_abertura || '')}
                                name="data_abertura"
                                id="data_abertura"
                                color="#528b46"
                                label="Data Abertura"
                                variant="outlined"
                                size="small"
                                style={{ width: auth.fk_gpu > 2 ? 0 : 190 }}
                                onChange={handleChange}
                                time={'S'}
                                noClear
                                hidden={auth.fk_gpu > 2}
                                disabled={editaCamposAdm}
                            />

                            <FrSelect
                                value={data.fk_usu_abertura || ''}
                                onChange={handleChange}
                                name="fk_usu_abertura"
                                id="fk_usu_abertura"
                                label='Usuário Abertura'
                                data={comboUsu}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 250 }}
                                hidden={auth.fk_gpu > 2}
                                disabled={editaCamposAdm}
                            />

                            <FrSelect
                                value={data.fk_csi || ''}
                                onChange={handleChange}
                                name="fk_csi"
                                id="fk_csi"
                                label='Situação'
                                data={comboCsi}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 200 }}
                                hidden={auth.fk_gpu > 2}
                            />

                            <FrSelect
                                value={data.publico || ''}
                                onChange={handleChange}
                                name="publico"
                                id="publico"
                                label='Público'
                                data={comboPublico}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 80 }}
                                hidden={auth.fk_gpu > 2}
                            />

                            <FrSelect
                                value={data.fk_usu_responsavel || ''}
                                onChange={handleChange}
                                name="fk_usu_responsavel"
                                id="fk_usu_responsavel"
                                label='Usuário Responsável'
                                data={comboUsuResp}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 200 }}
                                hidden={auth.fk_gpu > 2}
                            />

                            <FrSelect
                                value={data.fk_usu_solicitante || ''}
                                onChange={handleChange}
                                name="fk_usu_solicitante"
                                id="fk_usu_solicitante"
                                label='Usuário Solicitante'
                                data={comboUsuSol}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 200 }}
                                hidden={auth.fk_gpu > 2}
                            />

                            {/* <FrSelect
                                value={data.fk_usu_atual || ''}
                                onChange={handleChange}
                                name="fk_usu_atual"
                                id="fk_usu_atual"
                                label='Atribuir para'
                                data={comboUsuAtual}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 200 }}
                                hidden={auth.fk_gpu > 2}
                            /> */}
<br/>
                            <FrTextInput

                            maxLength='5'
                            value={data.tempo_estimado || ''}
                            name="tempo_estimado"
                            id="tempo_estimado"
                            color="#9c9595"
                            onChange={handleChange}
                            label="Tempo Estimado"
                            variant="outlined"
                            size="small"
                            hidden={auth.fk_gpu === 3}
                            style={{ width: 150 }}
                            />

                            <FrDatePicker
                                value={(data.data_previsao || '').split('T')}
                                name="data_previsao"
                                id="data_previsao"
                                color="#528b46"
                                label="Previsão de Conclusão"
                                variant="outlined"
                                size="small"
                                style={{ width: 190, }}
                                onChange={handleChange}
                                disabled={auth.fk_gpu > 2}
                                hidden={auth.fk_gpu > 2 && !codCha }
                            />

                            <FrTextInput

                                maxLength='3'
                                value={data.prioridade || ''}
                                onChange={handleChange}
                                name="prioridade"
                                id="prioridade"
                                color="#9c9595"
                                label="Prioridade"
                                variant="outlined"
                                size="small"
                                style={{ width: 100 }}
                                hidden={auth.fk_gpu > 2}
                            />
                            <br />
                            <FrDatePicker
                                value={(data.formatdata_encerramento || '')}
                                name="data_encerramento"
                                id="data_encerramento"
                                color="#528b46"
                                label="Data Encerramento"
                                variant="outlined"
                                size="small"
                                style={{ width: 190 }}
                                onChange={handleChange} noClear
                                hidden={!data.data_encerramento}
                                disabled={editaCamposAdm}
                                time='S'
                            />
                            <FrTextInput

                                maxLength='300'
                                value={data.nomeusuencerramento || ''}
                                color="#9c9595"
                                label="Usuário Encerramento"
                                variant="outlined"
                                size="small"
                                style={{ width: 350 }}
                                disabled={editaCamposAdm}
                                hidden={!data.nomeusuencerramento}
                            />

                            <br />

                            <FrTextInput

                                maxLength='300'
                                value={data.titulo || ''}
                                onChange={handleChange}
                                name="titulo"
                                id="titulo"
                                color="#9c9595"
                                label="Título"
                                variant="outlined"
                                size="small"
                                style={{ width: 350 }}
                                disabled={updateType && auth.fk_gpu > 2}
                            />
                            <br />
                            <label>Detalhe o problema ocorrido, informe o caminho para chegar à tela, os parâmetros utilizados. Em caso de melhoria, descreva com maior número de detalhes possível.</label>
                            <FrTextInput
                                maxLength='1000'
                                value={data.detalhamento || ''}
                                onChange={handleChange}
                                name='detalhamento'
                                id='detalhamento'
                                color='#49573B'
                                label='Detalhamento'
                                variant="filled"
                                size='small'
                                required
                                multiline
                                rows="8"
                                rowsMax="8"
                                fullWidth
                                disabled={updateType && auth.fk_gpu > 2}
                            />

                            <br />
                            <FrTextInput
                                maxLength='500'
                                value={data.resolucao || ''}
                                color='#49573B'
                                label='Resolução'
                                variant="filled"
                                size='small'
                                required
                                multiline
                                rows="4"
                                rowsMax="4"
                                fullWidth
                                hidden={!data.resolucao}
                                disabled
                            />

                            <br />

                            <div hidden={!updateType} className="tableDiv">
                                <FrTable 
                                    regName="chamados" 
                                    columns={columns} 
                                    searched={false} 
                                    codeName="pk_cha" 
                                    page={'chamados'} 
                                    data={dataMov} 
                                    readOnly={true} 
                                    order='desc' 
                                />
                            </div>

                            <div className="divAnexos">
                                <div className="grupoTitulo">
                                    Anexos
                                </div>
                                <div className="grupoEdits">
                                    <button onClick={(event) => selectFile(event, '')}>Anexar Arquivo</button>

                                    <div className="anexos">
                                        <input
                                            type="file"
                                            name="arquivo1"
                                            id="arquivo11"
                                            onChange={handleSelectedFile}
                                            hidden
                                            />
                                        <FrTextInput
                                            value={data.arquivo1 || ''}
                                            onChange={handleChange}
                                            name="arquivo1"
                                            id="arquivo1"
                                            color="#49573B"
                                            label={data.arquivo1 ? "Arquivo" : "Clique para anexar um arquivo"}
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            style={{ width: 300 }}
                                            hidden={!data.arquivo1}
                                            startAdornment={
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                        <Icon icon={search} size={14} onClick={(e) => { handleView(1); }} />
                                                    </div>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                        <Icon icon={download2} size={14} onClick={(e) => { handleDownload(1); }} />
                                                    </div>
                                                </div>

                                            }
                                            endAdornment={
                                                <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                                    <Icon icon={cross} size={13} onClick={(e) => { handleDelete(1); }} />
                                                </div>
                                            }
                                        />

                                        <input
                                            type="file"
                                            name="arquivo2"
                                            id="arquivo21"
                                            onChange={handleSelectedFile}
                                            hidden
                                        />
                                        <FrTextInput
                                            value={data.arquivo2 || ''}
                                            onChange={handleChange}
                                            name="arquivo2"
                                            id="arquivo2"
                                            color="#49573B"
                                            label={data.arquivo2 ? "Arquivo" : "Clique para anexar um arquivo"}
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            style={{ width: 300 }}
                                            hidden={!data.arquivo2}
                                            startAdornment={
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                        <Icon icon={search} size={14} onClick={(e) => { handleView(2); }} />
                                                    </div>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                        <Icon icon={download2} size={14} onClick={(e) => { handleDownload(2); }} />
                                                    </div>
                                                </div>

                                            }
                                            endAdornment={
                                                <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                                    <Icon icon={cross} size={13} onClick={(e) => { handleDelete(2); }} />
                                                </div>
                                            }
                                        />

                                        <input
                                            type="file"
                                            name="arquivo3"
                                            id="arquivo31"
                                            onChange={handleSelectedFile}
                                            hidden
                                        />
                                        <FrTextInput
                                            value={data.arquivo3 || ''}
                                            onChange={handleChange}
                                            name="arquivo3"
                                            id="arquivo3"
                                            color="#49573B"
                                            label={data.arquivo3 ? "Arquivo" : "Clique para anexar um arquivo"}
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            style={{ width: 300 }}
                                            hidden={!data.arquivo3}
                                            startAdornment={
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                        <Icon icon={search} size={14} onClick={(e) => { handleView(3); }} />
                                                    </div>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                        <Icon icon={download2} size={14} onClick={(e) => { handleDownload(3); }} />
                                                    </div>
                                                </div>

                                            }
                                            endAdornment={
                                                <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                                    <Icon icon={cross} size={13} onClick={(e) => { handleDelete(3); }} />
                                                </div>
                                            }
                                        />

                                        <input
                                            type="file"
                                            name="arquivo4"
                                            id="arquivo41"
                                            onChange={handleSelectedFile}
                                            hidden
                                        />
                                        <FrTextInput
                                            value={data.arquivo4 || ''}
                                            onChange={handleChange}
                                            name="arquivo4"
                                            id="arquivo4"
                                            color="#49573B"
                                            label={data.arquivo4 ? "Arquivo" : "Clique para anexar um arquivo"}
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            style={{ width: 300 }}
                                            hidden={!data.arquivo4}
                                            startAdornment={
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                        <Icon icon={search} size={14} onClick={(e) => { handleView(4); }} />
                                                    </div>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                        <Icon icon={download2} size={14} onClick={(e) => { handleDownload(4); }} />
                                                    </div>
                                                </div>

                                            }
                                            endAdornment={
                                                <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                                    <Icon icon={cross} size={13} onClick={(e) => { handleDelete(4); }} />
                                                </div>
                                            }

                                        />

                                        <input
                                            type="file"
                                            name="arquivo5"
                                            id="arquivo51"
                                            onChange={handleSelectedFile}
                                            hidden
                                        />
                                        <FrTextInput
                                            value={data.arquivo5 || ''}
                                            onChange={handleChange}
                                            name="arquivo5"
                                            id="arquivo5"
                                            color="#49573B"
                                            label={data.arquivo5 ? "Arquivo" : "Clique para anexar um arquivo"}
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            style={{ width: 300 }}
                                            hidden={!data.arquivo5}
                                            startAdornment={
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                        <Icon icon={search} size={14} onClick={(e) => { handleView(5); }} />
                                                    </div>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                        <Icon icon={download2} size={14} onClick={(e) => { handleDownload(5); }} />
                                                    </div>
                                                </div>

                                            }
                                            endAdornment={
                                                <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                                    <Icon icon={cross} size={13} onClick={(e) => { handleDelete(5); }} />
                                                </div>
                                            }

                                        />


                                        <input
                                            type="file"
                                            name="arquivo6"
                                            id="arquivo61"
                                            onChange={handleSelectedFile}
                                            hidden
                                        />
                                        <FrTextInput
                                            value={data.arquivo6 || ''}
                                            onChange={handleChange}
                                            name="arquivo6"
                                            id="arquivo6"
                                            color="#49573B"
                                            label={data.arquivo6 ? "Arquivo" : "Clique para anexar um arquivo"}
                                            variant="outlined"
                                            size="small"
                                            disabled
                                            style={{ width: 300 }}
                                            hidden={!data.arquivo6}
                                            startAdornment={
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px', marginLeft: '10px' }}                                        >
                                                        <Icon icon={search} size={14} onClick={(e) => { handleView(6); }} />
                                                    </div>
                                                    <div style={{ color: '#528b46', cursor: 'pointer', marginRight: '10px' }}                                        >
                                                        <Icon icon={download2} size={14} onClick={(e) => { handleDownload(6); }} />
                                                    </div>
                                                </div>

                                            }
                                            endAdornment={
                                                <div style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}                                          >
                                                    <Icon icon={cross} size={13} onClick={(e) => { handleDelete(6); }} />
                                                </div>
                                            }
                                        />

                                    </div>
                                </div>

                                <div className="regButtonsDiv">
                                    <button id='confirmar' onClick={handleSubmit}>Confirmar</button>
                                    <button id='cancelar' onClick={handleCancel}>Cancelar</button>
                                </div>


                            </div>

                        </form>
                    </div >
                    <FooterDelphus />
                </div >
            </div >
        </div >



    );
}

export default Inicio;
