import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import { MDBContainer, MDBIframe } from "mdbreact";
import Dialog from '@material-ui/core/Dialog';
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker';
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { conferePermissao, paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)



const RelChamados = () => {

  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('S')
  const [showModal, setShowModal] = useState(false)
  const [enableEdit, setenableEdit] = useState('N')
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [arquivo, setArquivo] = useState({})
  const [comboCliente, setComboCliente] = useState([{ value: '', display: '' }])
  const [comboUsuSol, setComboUsuSol] = useState([{ value: '', display: '' }]);

  const columns = [
    { id: 'cnpj_cpf', numeric: false, label: 'CNPJ', align: 'left', minWidth: 200 },
    { id: 'razao_social', numeric: false, label: 'Razão Social', align: 'left', minWidth: 150 },
    { id: 'fantasia', numeric: false, label: 'Fantasia', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Relatório Chamados'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        setComboSituacao(await api.getComboData('chamado_situacao'))
        setComboCliente(await api.getComboData('clientes'))
        
        setComboUsuSol(await api.getComboData('usuarios'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          // getData(event)
        }
      }
    }
    fetchData()
  }, [])


  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    let arq;
    // console.log(queryObject)
    // if ((queryObject.fk_usu_sol)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/suporte/rel_chamados?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/rel_chamados/relatorio', { params: queryObject, responseType: 'blob' }).then(r => {
        // setData(r.data)
        console.log(r.data)
        arq = URL.createObjectURL(r.data)
        setArquivo(arq)
        setArquivo(arq)
        setShowModal(true)
        swal.close()
      })
    } else {
      swal.fire('Solicitante não informado!', 'Informe solicitante para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/suporte/rel_chamados');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  function mostraModal(e) {
    e.preventDefault()
    getData(e)
  }

  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
  }



  return (
    <div className="Manut">
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Relatório de Chamados</h3>
          <UserOptions />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrSelect
                value={filter.fk_cli || ''}
                style={{ width: 420 }}
                name="fk_cli"
                id="fk_cli"
                label='Cliente'
                data={comboCliente}
                onChange={handleChange}
              />
              <FrDatePicker
                value={(filter.data_ini_abertura || '').split('T')[0]}
                name="data_ini_abertura"
                id="data_ini_abertura"
                color="#528b46"
                label="Período de Abertura"
                variant="outlined"
                size="small"
                style={{ width: 220 }}
                onChange={handleChange}
              />  

              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker
                value={(filter.data_fim_abertura || '').split('T')[0]}
                name="data_fim_abertura"
                id="data_fim_abertura"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 220 }}
                onChange={handleChange}
              />  
            
            <FrDatePicker
                value={(filter.data_ini_encerramento || '').split('T')[0]}
                name="data_ini_encerramento"
                id="data_ini_encerramento"
                color="#528b46"
                label="Período de Encerramento"
                variant="outlined"
                size="small"
                style={{ width: 220 }}
                onChange={handleChange}
              />  

              <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

              <FrDatePicker
                value={(filter.data_fim_encerramento || '').split('T')[0]}
                name="data_fim_encerramento"
                id="data_fim_encerramento"
                color="#528b46"
                variant="outlined"
                size="small"
                style={{ width: 220 }}
                onChange={handleChange}
              />  
            <FrSelect
                value={filter.fk_sit || ''}
                style={{ width: 170 }}
                name="fk_sit"
                id="fk_sit"
                label='Situação'
                data={comboSituacao}
                onChange={handleChange}
              />
              <br/>
              <FrSelect
                value={filter.fk_usu_sol || ''}
                style={{ width: 350 }}
                name="fk_usu_sol"
                id="fk_usu_sol"
                label='Solicitante'
                data={comboUsuSol}
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={mostraModal}>
                <Icon icon={ic_search} size={18} />
                Emitir
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
             
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="relChamados" columns={columns} searched={filtered} codeName="pk_cli" page={'RelChamados'} data={data} enableEdit={enableEdit} enableDel={'S'} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  )

}

export default RelChamados;