import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import NavBar from '../components/NavBar';
import FrTextInput from '../components/FrTextInput';
import FrSelect from '../components/FrSelect';
import UserOptions from '../components/UserOptions';
import FrLoading from '../components/FrLoading';
import api from '../services/api';
import { onlyNumbers, paramsToObject } from '../utils/functions';
import { mTel } from '../utils/masks';

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import FrListSelect from '../components/FrListSelect'

const swal = withReactContent(Swal)

function Usuarios() {
  const [data, setData] = useState({});
  const [updateType, setUpdateType] = useState(false);
  const [comboGrupoUsu, setComboGrupoUsu] = useState([{ value: '', display: '' }]);
  const [enableEdit, setEnableEdit] = useState('N');
  const [listEmpresas, setListEmpresas] = useState([]);
  const [checkedEmpresas, setCheckedEmpresas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Usuários'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)

        if (query.codigo > 0) {
          setEnableEdit(await api.getAcessos('Usuários', 'Alteracao'))
        } else {
          setEnableEdit(await api.getAcessos('Usuários', 'Inclusao'))
        }

        setComboGrupoUsu(await api.getComboData('grupo_usuarios'))
        setListEmpresas(await api.getListData('clientes'))

        if (query.codigo > 0) {
          let auxValues;
          setUpdateType(true)
          api.get('/usuarios?pk_usu=' + query.codigo).then(r => {
            if (r.data[0]) {
              console.log(r.data[0]);
              auxValues = r.data[0];
              if (auxValues.fone1) auxValues.fone1 = mTel(auxValues.fone1);
              if (auxValues.fone2) auxValues.fone2 = mTel(auxValues.fone2);
              setData(auxValues);
              api.get('/usuarios/clientes?pk_usu=' + query.codigo).then(r => {
                setCheckedEmpresas(r.data.pk_cli)
              })

              swal.close()
            } else {
              swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                .then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
            }
          })
        } else swal.close()
      }
    }
    fetchData()
  }, [])


  function consistData(data) {
    let required = [
      'nome',
      'fk_gpu',
      'email',
    ]
    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_gpu') fieldName = 'GRUPO DE USUÁRIO'
      if (curr === 'email') fieldName = 'LOGIN'

      if (!data[curr]) return errors.push(fieldName);
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        auxValues.pk_cli = checkedEmpresas
        auxValues.fone1 = auxValues.fone1 ? onlyNumbers(auxValues.fone1) : null;
        auxValues.fone2 = auxValues.fone2 ? onlyNumbers(auxValues.fone2) : null;
        if (errors.length === 0) {
          if (updateType) {
            api.post('/usuarios/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/usuarios/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fone1': case 'fone2':
        auxValues[e.target.name] = mTel(e.target.value);
        break;
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Usuário</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='45'
            value={data.nome || ''}
            onChange={handleChange}
            name="nome"
            id="nome"
            color="#49573B"
            label="Nome"
            variant="outlined"
            size="small"
            required
            style={{ width: 320 }}
            disabled={updateType}
          />


          <FrSelect
            value={data.fk_gpu || ''}
            onChange={handleChange}
            name="fk_gpu"
            id="fk_gpu"
            label='Grupo de Usuário'
            data={comboGrupoUsu}
            style={{ width: 223 }}
          />

          <FrTextInput
            maxLength='45'
            value={data.fone1 || ''}
            onChange={handleChange}
            name="fone1"
            id="fone1"
            color="#49573B"
            label="Fone 1"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
          />
          <FrTextInput
            maxLength='45'
            value={data.fone2 || ''}
            onChange={handleChange}
            name="fone2"
            id="fone2"
            color="#49573B"
            label="Fone 2"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
          />          
          <br />
          <div className="meioCampos">
            <div>
              Empresas que este usuário pode acessar:
              <FrListSelect pk="pk_cli" text="nomeemp" data={listEmpresas} setChecked={setCheckedEmpresas} checked={checkedEmpresas} />
            </div>
          </div>

          <br />
          <p>Informações para Login</p>
          <FrTextInput
            maxLength='200'
            value={data.email || ''}
            onChange={handleChange}
            name="email"
            id="email"
            color="#49573B"
            label="Login"
            variant="outlined"
            size="small"
            required
            style={{ width: 350 }}
            disabled={updateType}
          />


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Usuarios;
