import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { ic_assignment } from 'react-icons-kit/md/ic_assignment'
import { ic_desktop_mac } from 'react-icons-kit/md/ic_desktop_mac'
import { ic_library_books } from 'react-icons-kit/md/ic_library_books'
import {fileText} from 'react-icons-kit/icomoon/fileText'

import Nav from 'react-bootstrap/Nav';
import Collapse from 'react-bootstrap/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import icon from '../../imgs/icon.png';
import logo from '../../imgs/delphus.png';

function NavBar(props) {
    const [hide, setHide] = useState(true);
    const [openCadastros, setOpenCadastros] = useState(false)
    const [openSistema, setOpenSistema] = useState(false)
    const [openOperacionais, setOpenOperacionais] = useState(false)
    const [openGerais, setOpenGerais] = useState(false)
    const [openRelatorios, setOpenRelatorios] = useState(false)


    function toggleNavBarHide(e, item) {
        e.preventDefault();

        if (!hide) {
            setOpenCadastros(false)
            setOpenOperacionais(false)
            setOpenSistema(false)
        }

        setHide(!hide)
    }

    function abreCadastro(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenCadastros(!openCadastros)

    }

    function abreRelatorios(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenRelatorios(!openRelatorios)

    }

    function abreSistema(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenSistema(!openSistema)

    }

    function abreOperacionais(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenOperacionais(!openOperacionais)

    }



    return (
        <div className={"Navbar" + (hide ? '-hide no-print' : ' no-print')}>
            <button onClick={toggleNavBarHide} className="toggle_navbar">
                {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
            </button>
            <Nav justify defaultActiveKey="/suporte/home" className="flex-column">
                <Tooltip title="Tela Principal">
                    <Nav.Link href="/suporte/home">
                        {hide ? <img alt='Expandir' src={icon} className="fr_icon_navbar" /> : <img alt='Encolher' src={logo} className="fr_logo_navbar" />}
                    </Nav.Link>
                </Tooltip>

                <button onClick={abreCadastro} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openCadastros ? " Open" : "")}>

                    <Icon icon={ic_assignment} size={20} />
                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Cadastros'}</strong>}
                </button>
                <Collapse in={openCadastros}>

                    <div className="toggleMenu">


                        <Tooltip title="Clientes">
                            <Nav.Link href="/suporte/clientes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Clientes'}</strong>}
                            </Nav.Link>
                        </Tooltip>


                        <button onClick={() => setOpenGerais(!openGerais)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openGerais ? " Open" : "")}>
                            <Icon icon={ic_library_books} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Gerais'}</strong>}
                        </button>

                        <Collapse in={openGerais}>

                            <div className="toggleMenuCollapse">

                                <Tooltip title="Estados">
                                    <Nav.Link href="/suporte/estados" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Estados'}</strong>}
                                    </Nav.Link>
                                </Tooltip>
                                <Tooltip title="Cidades">
                                    <Nav.Link href="/suporte/cidades" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Cidades'}</strong>}
                                    </Nav.Link>
                                </Tooltip>
                                <Tooltip title="Modulos">
                                    <Nav.Link href="/suporte/modulos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Modulos'}</strong>}
                                    </Nav.Link>
                                </Tooltip>
                                <Tooltip title="Modulo Situação">
                                    <Nav.Link href="/suporte/modulos_situacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Modulo Situação'}</strong>}
                                    </Nav.Link>
                                </Tooltip>
                                <Tooltip title="Tipos de Chamados">
                                    <Nav.Link href="/suporte/tipo_chamado" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipos de Chamados'}</strong>}
                                    </Nav.Link>
                                </Tooltip>
                                <Tooltip title="Situação de chamado">
                                    <Nav.Link href="/suporte/chamado_situacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação de chamado'}</strong>}
                                    </Nav.Link>
                                </Tooltip>
                                <Tooltip title="Tipos de Movimentação">
                                    <Nav.Link href="/suporte/tipo_movimentacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipos de Movimentação'}</strong>}
                                    </Nav.Link>
                                </Tooltip>

                            </div>

                        </Collapse>
                    </div>

                </Collapse>
                <button onClick={abreOperacionais} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openOperacionais ? " Open" : "")}>
                    <Icon icon={ic_library_books} size={20} />
                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Operacionais'}</strong>}
                </button>

                <Collapse in={openOperacionais}>

                    <div className="toggleMenuCollapse">
                        <Tooltip title="Chamados">
                            <Nav.Link href="/suporte/chamados?first_acc=true" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Chamados'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                    </div>

                </Collapse>
                <button onClick={abreRelatorios} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openRelatorios ? " Open" : "")}>
                    <Icon icon={fileText} size={20} />
                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Relatórios'}</strong>}
                </button>

                <Collapse in={openRelatorios}>

                    <div className="toggleMenuCollapse">
                        <Tooltip title="Relatório por Cliente">
                            <Nav.Link href="/suporte/rel_chamados?first_acc=true" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Relatório por Cliente'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                    </div>

                </Collapse>



                <button onClick={abreSistema} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openSistema ? " Open" : "")}>
                    <Icon icon={ic_desktop_mac} size={20} />
                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Sistema'}</strong>}
                </button>
                <Collapse in={openSistema}>
                    <div className="toggleMenu">
                        <Tooltip title="Grupo de Usuários">
                            <Nav.Link href="/suporte/grupo_usuarios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Grupo de Usuários'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Usuários">
                            <Nav.Link href="/suporte/usuarios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Usuários'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                    </div>
                </Collapse>

            </Nav>

        </div>
    );
}

export default NavBar;