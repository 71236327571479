import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import NavBar from "../components/NavBar";
import FrTextInput from "../components/FrTextInput";
import FrTextField from "../components/FrTextField";
import UserOptions from "../components/UserOptions";
import FrLoading from "../components/FrLoading";
import FrSelect from "../components/FrSelect";
import Tabs from '../components/Tabs';
import FrTableReg from '../components/FrTableReg';
import { Icon } from 'react-icons-kit'

import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import api from "../services/api";
import consultaCep from "../services/consultaCep";
import { conferePermissao, paramsToObject, validarCNPJ, onlyNumbers, validarCPF } from "../utils/functions";
import { mCNPJ, mTel, mCEP, mCPF } from "../utils/masks";

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';


const swal = withReactContent(Swal);

const RegCliente = () => {

    const [data, setData] = useState({ ativo: 'S' });
    const [updateType, setUpdateType] = useState(false);
    const [enableEdit, setEnableEdit] = useState('N');
    const [erroCpfCnpj, setErroCpfCnpj] = useState(false);
    const [modal, setModal] = useState(false);
    const [comboCid, setComboCid] = useState([{ value: '', display: '' }]);
    const [comboEst, setComboEst] = useState([{ value: '', display: '' }]);
    const [pkModal, setPkModal] = useState(0)
    const [updatemodal, setUpdateModal] = useState(false)
    const [pkCli, setPkCli] = useState(0);

    const [dataCon, setDataCon] = useState([{ display: '' }])
    const [con_servidor, setCon_servidor] = useState();
    const [con_software, setCon_software] = useState();
    const [con_ip, setCon_ip] = useState();
    const [con_senha, setCon_senha] = useState();
    const [con_usuario, setCon_usuario] = useState();
    const [con_tipo_conexao, setCon_tipo_conexao] = useState();
    const [con_observacoes, setCon_observacoes] = useState();
    const [excluidosCon, setExcluidosCon] = useState([])

    const columnsCon = [
        { id: 'servidor', numeric: false, label: 'Servidor', align: 'left', minWidth: 100 },
        { id: 'software', numeric: false, label: 'Software', align: 'left', minWidth: 200 },
        { id: 'ip', numeric: false, label: 'IP', align: 'left', minWidth: 150 },
    ]

    useEffect(() => {
        const fetchData = async () => {
            let tela = 'Clientes'
            let consulta = await api.getAcessos(tela, 'Consulta')
            if (consulta !== 'S') {
                swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
                    .then((result) => {
                        if (result.value) {
                            window.history.back()
                        }
                    })
            } else {
                swal.fire({
                    html: <FrLoading text="Carregando..." />,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });

                const urlParams = new URLSearchParams(window.location.search);
                let query = paramsToObject(urlParams);

                setComboEst(await api.getComboData('estados'));

                if (query.codigo > 0) {
                    setPkCli(query.codigo)
                    setUpdateType(true);
                    api.get('/clientes?pk_cli=' + query.codigo).then(r => {
                        if (r.data[0]) {
                            console.log(r.data[0])
                            setData(r.data[0]);
                            api.get('/conexao_cliente?fk_cli=' + query.codigo).then(r => {
                                if (r.data.length) {
                                    console.log(r.data)
                                    setDataCon(r.data);
                                }
                            })
                        } else {
                            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                                .then((result) => {
                                    if (result.value) {
                                        window.history.back();
                                    }
                                })
                        }
                    });
                } else { }
                swal.close();
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchCidades = async () => {
            if (data.fk_est > 0) {
                swal.fire({
                    html: <FrLoading text="Buscando Cidades..." />,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });
                setComboCid(await api.getComboData('cidades', data.fk_est));
                swal.close();
            }
        }

        fetchCidades();
    }, [data.fk_est]);

    const handleCpfCnpj = async (e) => {
        e.preventDefault();

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams);

        let cpfCnpj = onlyNumbers(e.target.value);
        const auxValues = { ...data };
        if (cpfCnpj.length <= 11) {
            if (!validarCPF(cpfCnpj) && cpfCnpj != "") {
                setErroCpfCnpj(true);
                swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning');
                auxValues.cnpj_cpf = '';
                setData(auxValues);
                // document.getElementById('cnpj_cpf').focus();
            } else if(query.codigo == 0){ //93284131000129
                api.get('/clientes?cnpj_cpf='+cpfCnpj).then(r => {
                    if (r.data.length > 0){
                        setErroCpfCnpj(true);
                        swal.fire('CPF duplicado', 'CPF já está cadastrado no sistema!', 'warning');
                        auxValues.cnpj_cpf = '';
                        setData(auxValues);
                        // document.getElementById('cnpj_cpf').focus();
                    } else {
                        setErroCpfCnpj(false);
                    }
                })
            }
        } else{
            if (!validarCNPJ(cpfCnpj) && cpfCnpj != "") {
                setErroCpfCnpj(true);
                swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning');
                auxValues.cnpj_cpf = '';
                setData(auxValues);
                // document.getElementById('cnpj_cpf').focus();
            } else if(query.codigo == 0) {
                api.get('/clientes?cnpj_cpf='+cpfCnpj).then(r => {
                    if (r.data.length > 0){
                        setErroCpfCnpj(true);
                        swal.fire('CPF duplicado', 'CPF já está cadastrado no sistema!', 'warning');
                        auxValues.cnpj_cpf = '';
                        setData(auxValues);
                        // document.getElementById('cnpj_cpf').focus();
                    } else {
                        setErroCpfCnpj(false);
                    }
                })
            }
        }
    }

    const handleCep = (e) => {
        e.preventDefault();
        if (e.target.value) {
            if (e.target.value) {
                let endereco = 'endereco';
                let bairro = 'bairro';
                let fk_est = 'fk_est';
                let fk_cid = 'fk_cid';

                if (e.target.id !== 'cep') {
                    endereco = 'endereco_cob'
                    bairro = 'bairro_cob';
                    fk_est = 'fk_est_cob';
                    fk_cid = 'fk_cid_cob';
                }

                consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(async r => {
                    if (r.data.erro) {
                        swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning');
                    } else {
                        const auxValues = { ...data };
                        auxValues[endereco] = r.data.logradouro;
                        auxValues[bairro] = r.data.bairro;

                        await api.get('/estados?uf=' + r.data.uf).then(r => {
                            if (r.data.length > 0)
                                auxValues[fk_est] = r.data[0].pk_est;
                        });

                        await api.get('/cidades?nome=' + r.data.localidade).then(r => {
                            if (r.data.length > 0)
                                auxValues[fk_cid] = r.data[0].pk_cid;
                        });

                        setData(auxValues);
                    }
                }).catch(err => {
                    swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning');
                });
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e);
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.value) {
                console.log(data)
                const auxValues = { ...data };
                console.log(auxValues)
                auxValues.dataCon = dataCon;

                auxValues.excluidosCon = excluidosCon;

                auxValues.cnpj_cpf = onlyNumbers(data.cnpj_cpf)

                const urlParams = new URLSearchParams(window.location.search);
                let query = paramsToObject(urlParams);

                console.log(auxValues, data.pk_cli);
                let errors = consistData(auxValues);

                if (data.insc_estadual && query.codigo) {
                    await api.get('/clientes?insc_estadual=' + onlyNumbers(data.insc_estadual)).then(r => {
                        if (r.data.length > 0 && r.data.pk_cli != data.pk_cli)
                            errors.push('Já existe cadastro com esta inscrição estadual!')
                    });
                }

                if ((data.cnpj_cpf)){
                    if (onlyNumbers(data.cnpj_cpf).length >= 14) {
                        await api.get('/clientes?cnpj_cpf=' + onlyNumbers(data.cnpj_cpf)).then(r => {
                            console.log(r.data)
                            if (r.data.length > 0 && r.data.pk_cli)
                                errors.push('Já existe cadastro com este cnpj!')
                        })
                    } else {
                        await api.get('/clientes?cnpj_cpf=' + onlyNumbers(data.cnpj_cpf)).then(r => {
                            console.log(r.data)
                            if (r.data.length > 0 && r.data.pk_cli)
                                errors.push('Já existe cadastro com este cpf!')
                        })
                    }
                }

                

                console.log('Erros: ' + errors.length)
                if (errors.length === 0) {
                    console.log(auxValues)
                    if (updateType) {
                        api.post('/clientes/edit', auxValues).then(r => {
                            console.log(auxValues)
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Alterado',
                                    text: "O registro foi alterado com sucesso!",
                                    icon: 'success'
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back();
                                    }
                                });
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi alterado!",
                                    icon: 'warning'
                                });
                            }
                        })
                    } else {
                        api.post('/clientes/add', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Adicionado',
                                    text: "O registro foi adicionado com sucesso!",
                                    icon: 'success'
                                }).then((result) => {
                                    console.log(result)
                                    if (result.value) {
                                        window.history.back();
                                    }
                                });
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi adicionado!",
                                    icon: 'warning'
                                });
                            }
                        });
                    }
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "Verifique inconsistências: " + errors.join(','),
                        icon: 'warning'
                    });
                }
            }
        });

    }

    function consistData(data) {
        let required = [
            'cnpj_cpf'
        ];

        let errors = [];

        required.forEach((curr, index) => {
            let fieldName = curr;
            if (curr === 'razao_social') fieldName = 'Razão Social';
            if (curr === 'fantasia') fieldName = 'Nome Fantasia';
            if (curr === 'cnpj_cpf') fieldName = 'CNPJ/CPF';
            if (curr === 'endereco') fieldName = 'Endereço';
            if (curr === 'bairro') fieldName = 'Bairro';
            if (curr === 'fk_cid') fieldName = 'Cidade';

            if (!data[curr]) return errors.push(fieldName);
        });

        return errors;
    }

    function handleCancel(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                window.location.href = '/suporte/clientes'
            }
        })
    }

    const handleChange = (e) => {
        // console.log(e.target.value==='')
        
        const auxValues = { ...data };
        if (e.target.value === ''){
            auxValues[e.target.name] = null;
        } else {
            switch (e.target.name) {
                case 'fone':
                case 'fone_2':
                    auxValues[e.target.name] = mTel(e.target.value);
                    break;

                case 'cnpj_cpf':
                    auxValues[e.target.name] = onlyNumbers(e.target.value).length <= 11 ? mCPF(e.target.value) : mCNPJ(e.target.value);
                    break;

                case 'cep':
                case 'cep_cob':
                    auxValues[e.target.name] = mCEP(e.target.value);
                    break;

                default: auxValues[e.target.name] = e.target.value;
            }
        }
        // console.log(auxValues)
        
        setData(auxValues);
    }

    function mostraModal(e) {
        e.preventDefault()
        setCon_servidor('')
        setCon_software('')
        setCon_ip('')
        setCon_senha('')
        setCon_usuario('')
        setCon_tipo_conexao('')
        setCon_observacoes('')
    
        setUpdateModal(false)
        setModal(true)
      }

    const handleChangeModal = (e) => {
        if (e.target.name == 'con_servidor')
            setCon_servidor(e.target.value)
        if (e.target.name == 'con_software')
            setCon_software(e.target.value)
        if (e.target.name == 'con_ip')
            setCon_ip(e.target.value)
        if (e.target.name == 'con_senha')
            setCon_senha(e.target.value)
        if (e.target.name == 'con_usuario')
            setCon_usuario(e.target.value)
        if (e.target.name == 'con_tipo_conexao')
            setCon_tipo_conexao(e.target.value)
        if (e.target.name == 'con_observacoes')
            setCon_observacoes(e.target.value)
    }

    function closeModal(e) {
        e.preventDefault()
        setModal(false)
    }

    function handleSubmitModalConexoes(e) {
        e.preventDefault()
        if ((con_servidor)) {
            if (!updatemodal)
                incluiCon(e)
            else
                updateCon(e)
          closeModal(e)
        } else
          swal.fire({
            title: 'Erro!',
            text: `Informe nome!`,
            icon: 'warning',
        })
    
    }

    function incluiCon(e) {
        e.preventDefault();
        let pk = -1
        let item = []
    
        if ((dataCon[0].nome === '') || (dataCon[0].display === '')) {
          setDataCon([{
            servidor: con_servidor,
            software: con_software,
            ip: con_ip,
            senha: con_senha,
            usuario: con_usuario,
            tipo_conexao: con_tipo_conexao,
            observacoes: con_observacoes,
            operacao: 'I'
          }])
    
        } else {
    
          var i;
          for (i = 0; i < dataCon.length; i++) {
            item.push({
              servidor: dataCon[i].servidor,
              software: dataCon[i].software,
              ip: dataCon[i].ip,
              senha: dataCon[i].senha,
              tipo_conexao: dataCon[i].tipo_conexao,
              observacoes: dataCon[i].observacoes,
              usuario: dataCon[i].usuario,
              operacao: dataCon[i].operacao
    
            })
            pk--
          }
          item.push({
            servidor: con_servidor,
            software: con_software,
            ip: con_ip,
            senha: con_senha,
            usuario: con_usuario,
            tipo_conexao: con_tipo_conexao,
            observacoes: con_observacoes,
            operacao: 'I'
          })
          pk--
          setDataCon(item)
        }
      }

      function updateCon(e) {
        e.preventDefault();
    
        let item = []
        let i
    
        for (i = 0; i < dataCon.length; i++) {
          if (dataCon[i].pk == pkModal) {
    
            item.push({
                servidor: con_servidor,
                software: con_software,
                ip: con_ip,
                senha: con_senha,
                usuario: con_usuario,
                tipo_conexao: con_tipo_conexao,
                observacoes: con_observacoes,
                operacao: (pkModal > 0) ? 'A' : 'I'
            })
          } else {
            item.push({
                servidor: dataCon[i].servidor,
                software: dataCon[i].software,
                ip: dataCon[i].ip,
                senha: dataCon[i].senha,
                tipo_conexao: dataCon[i].tipo_conexao,
                observacoes: dataCon[i].observacoes,
                usuario: dataCon[i].usuario,
                operacao: dataCon[i].operacao
            })
          }
    
          if (i + 1 == dataCon.length)
            setDataCon(item)
        }
    
      }

    return (
        <div className="defaultReg">
            <NavBar />
            <div className="defaultRegContent">
                <div className="defaultHeader">
                    <h3>{updateType ? 'Edição' : "Inclusão"}</h3>
                    <UserOptions />
                </div>
                <br />
                <form id="regForm">
                    <h4>Dados Gerais</h4>
                    <div className="dadosGeraisCliente">

                        <FrTextInput
                            maxLength='18'
                            value={data.cnpj_cpf || ''}
                            onChange={handleChange}
                            name="cnpj_cpf"
                            id="cnpj_cpf"
                            color="#49573B"
                            label="CNPJ/CPF"
                            variant="outlined"
                            size="small"
                            disable={updateType}
                            required
                            style={{ width: 192 }}
                            onBlur={handleCpfCnpj}
                            erro={erroCpfCnpj}
                        />

                        <FrTextInput
                            maxLength='150'
                            value={data.razao_social || ''}
                            onChange={handleChange}
                            name="razao_social"
                            id="razao_social"
                            color="#49573B"
                            label="Razão Social"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 435 }}
                        />

                        <FrTextInput
                            maxLength='50'
                            value={data.fantasia || ''}
                            onChange={handleChange}
                            name="fantasia"
                            id="fantasia"
                            color="#49573B"
                            label="Nome Fantasia"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 436 }}
                        />
                        <br />

                        <FrTextInput
                            maxLength='25'
                            value={data.insc_estadual || ''}
                            onChange={handleChange}
                            name="insc_estadual"
                            id="insc_estadual"
                            color="#49573B"
                            label="Inscrição Estadual"
                            variant="outlined"
                            size="small"
                            style={{ width: 200 }}
                        />

                        <FrTextInput
                            maxLength='20'
                            value={data.insc_municipal || ''}
                            onChange={handleChange}
                            name="insc_municipal"
                            id="insc_municipal"
                            color="#49573B"
                            label="Inscrição Municipal"
                            variant="outlined"
                            size="small"
                            style={{ width: 200 }}
                        />

                        <FrTextInput
                            maxLength='10'
                            value={data.cep || ''}
                            onChange={handleChange}
                            onBlur={handleCep}
                            name="cep"
                            id="cep"
                            color="#49573B"
                            label="CEP"
                            variant="outlined"
                            size="small"
                            style={{ width: 180 }}
                        />

                        <FrTextInput
                            maxLength='100'
                            value={data.endereco || ''}
                            onChange={handleChange}
                            fullWidth
                            name="endereco"
                            id="endereco"
                            color="#49573B"
                            label="Endereço"
                            variant="outlined"
                            size="small"
                            style={{ width: 350 }}
                        />

                        <FrTextInput
                            maxLength='45'
                            value={data.complemento || ''}
                            onChange={handleChange}
                            name="complemento"
                            id="complemento"
                            color="#49573B"
                            label="Complemento"
                            variant="outlined"
                            size="small"
                            style={{ width: 120 }}
                        />
                        <br />

                        <FrTextInput
                            maxLength='45'
                            value={data.bairro || ''}
                            onChange={handleChange}
                            name="bairro"
                            id="bairro"
                            color="#49573B"
                            label="Bairro"
                            variant="outlined"
                            size="small"
                            style={{ width: 200 }}
                        />

                        <FrSelect
                            value={data.fk_est || ''}
                            onChange={handleChange}
                            name="fk_est"
                            id="fk_est"
                            label='Estado'
                            data={comboEst}
                            style={{ width: 180 }}
                        />

                        <FrSelect
                            value={data.fk_cid || ''}
                            onChange={handleChange}
                            name="fk_cid"
                            id="fk_cid"
                            label='Cidade'
                            data={comboCid}
                            style={{ width: 250 }}
                            required
                        />

                        <FrTextInput
                            value={data.email_nfe || ''}
                            onChange={handleChange}
                            name="email_nfe"
                            id="email_nfe"
                            label="Email NFe"
                            variant="outlined"
                            size="small"
                            style={{ width: 426 }}
                        />

                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <div className='grupoTitulo'>
                                Dados de Cobrança
                            </div>

                            <div style={{ paddingTop: '10px' }}>
                                <FrTextInput
                                    maxLength='10'
                                    value={data.cep_cob || ''}
                                    onChange={handleChange}
                                    onBlur={handleCep}
                                    name="cep_cob"
                                    id="cep_cob"
                                    color="#49573B"
                                    label="CEP"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 130 }}
                                />

                                <FrTextInput
                                    maxLength='100'
                                    value={data.endereco_cob || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    name="endereco_cob"
                                    id="endereco_cob"
                                    color="#49573B"
                                    label="Endereço"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 412 }}
                                />

                                <FrTextInput
                                    maxLength='45'
                                    value={data.complemento_cob || ''}
                                    onChange={handleChange}
                                    name="complemento_cob"
                                    id="complemento_cob"
                                    color="#49573B"
                                    label="Complemento"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 120 }}
                                />

                                <FrTextInput
                                    maxLength='45'
                                    value={data.bairro_cob || ''}
                                    onChange={handleChange}
                                    name="bairro_cob"
                                    id="bairro_cob"
                                    color="#49573B"
                                    label="Bairro"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 200 }}
                                />

                                <FrSelect
                                    value={data.fk_cid_cob || ''}
                                    onChange={handleChange}
                                    name="fk_cid_cob"
                                    id="fk_cid_cob"
                                    label='Cidade'
                                    data={comboCid}
                                    style={{ width: 216 }}
                                    required
                                />

                                <FrTextInput
                                    value={data.email_cob || ''}
                                    onChange={handleChange}
                                    name="email_cob"
                                    id="email_cob"
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 280 }}
                                />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='grupoTitulo'>
                                Contatos
                            </div>

                            <div style={{ paddingTop: '10px' }}>

                                <FrTextInput
                                    maxLength='15'
                                    value={data.fone || ''}
                                    onChange={handleChange}
                                    name="fone"
                                    id="fone"
                                    color="#49573B"
                                    label="Fone 1"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 200 }}
                                />

                                <FrTextInput
                                    maxLength='15'
                                    value={data.fone_2 || ''}
                                    onChange={handleChange}
                                    name="fone_2"
                                    id="fone_2"
                                    color="#49573B"
                                    label="Fone 2"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 200 }}
                                />

                            </div>
                        </div>
                    </div>
                    <Tabs setModal={setModal} aba1_caption="Conexões" aba1_hidden={false} 
                        Aba1={<div>
                            <div className="grupoEdits">
                                <button onClick={(e) => mostraModal(e)}>
                                <Icon icon={ic_add_box} size={18} />
                                    Incluir
                                </button>

                                <div >
                                <FrTableReg width={95} setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setCon_servidor={setCon_servidor} 
                                    setCon_software={setCon_software} setCon_ip={setCon_ip} setCon_senha={setCon_senha} setCon_usuario={setCon_usuario} setCon_tipo_conexao={setCon_tipo_conexao} 
                                    setCon_observacoes={setCon_observacoes}
                                    editar={true} setDataGrid={setDataCon} detail regName="contatos" columns={columnsCon} arrayExcluidos={excluidosCon} setArrayExcluidos={setExcluidosCon} 
                                    searched={false} codeName="display" page={''} data={dataCon}
                                    functionDelete={'/clientes/deletaCon/'} sortable={true} orderBy={'nome'}
                                />
                                <div className="register" hidden={!modal}>
                                    <br /><br />
                                    <div className="fields">

                                    <FrTextInput
                                        maxLength='100'
                                        value={con_servidor || ''}
                                        onChange={handleChangeModal}
                                        name="con_servidor"
                                        id="con_servidor"
                                        color="#49573B"
                                        label="Servidor"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 300 }}
                                    />

                                    <FrTextInput
                                        maxLength='100'
                                        value={con_software || ''}
                                        onChange={handleChangeModal}
                                        name="con_software"
                                        id="con_software"
                                        color="#49573B"
                                        label="Software de Conexão"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 300 }}
                                    />                                    

                                    <FrTextInput
                                        maxLength='100'
                                        value={con_ip || ''}
                                        onChange={handleChangeModal}
                                        name="con_ip"
                                        id="con_ip"
                                        color="#49573B"
                                        label="IP"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 300 }}
                                    />

                                    <FrTextInput
                                        maxLength='50'
                                        value={con_usuario || ''}
                                        onChange={handleChangeModal}
                                        name="con_usuario"
                                        id="con_usuario"
                                        color="#49573B"
                                        label="Usuário"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 300 }}
                                    />

                                    <FrTextInput
                                        maxLength='100'
                                        value={con_senha || ''}
                                        onChange={handleChangeModal}
                                        name="con_senha"
                                        id="con_senha"
                                        color="#49573B"
                                        label="Senha"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 300 }}
                                    />

                                    <FrTextInput
                                        maxLength='50'
                                        value={con_tipo_conexao || ''}
                                        onChange={handleChangeModal}
                                        name="con_tipo_conexao"
                                        id="con_tipo_conexao"
                                        color="#49573B"
                                        label="Tipo de Conexão"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 300 }}
                                    />
                                    <br />
                                    <FrTextInput
                                        maxLength='500'
                                        value={con_observacoes || ''}
                                        onChange={handleChangeModal}
                                        multiline={true}
                                        name="con_observacoes"
                                        id="con_observacoes"
                                        color="#49573B"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        rows="8"
                                        style={{ width: 1000 }}
                                    />

                                    <div className="regButtonsDiv">
                                        <button onClick={handleSubmitModalConexoes}>Salvar</button>
                                        <button onClick={closeModal}>Cancelar</button>
                                    </div>

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>}
                    />

                </form>
                <div className="regButtonsDiv">
                    <button onClick={handleSubmit}>Salvar</button>
                    <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
                </div>
                <FooterDelphus />
            </div>
        </div>
    );

}

export default RegCliente;