import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrTable from '../components/FrTable';
import UserOptions from '../components/UserOptions';
import FrTextInput from '../components/FrTextInput'
import { Icon } from 'react-icons-kit';
import { Link } from 'react-router-dom';
import { ic_add_box } from 'react-icons-kit/md/ic_add_box';
import { ic_search } from 'react-icons-kit/md/ic_search';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import api from "../services/api";
import FrDatePicker from '../components/FrDatePicker';
import { isMobile } from 'react-device-detect';
import { ic_sync } from 'react-icons-kit/md/ic_sync'
import {spinner11} from 'react-icons-kit/icomoon/spinner11'

import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions';
import NavBar from '../components/NavBar';

import FrLoading from '../components/FrLoading';
import FrSelect from "../components/FrSelect";

import './styles/Login.css';

import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function Chamados() {

    const [data, setData] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [filter, setFilter] = useState({});
    const [comboMod, setComboMod] = useState([{ value: '', display: '' }]);
    const [comboCli, setComboCli] = useState([{ value: '', display: '' }]);
    const [auth, setAuth] = useState(JSON.parse(localStorage['authDelphus'] || JSON.stringify({ authenticated: false, user: 0, fk_gpu: 0 })));
    const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }]);
    const [comboUsuAtual, setComboUsuAtual] = useState([{ value: '', display: '' }]);
    const [comboUsuDev, setComboUsuDev] = useState([{ value: '', display: '' }]);
    const [comboCsi, setComboCsi] = useState([{ value: '', display: '' }]);
    const [comboUsuResp, setComboUsuResp] = useState([{ value: '', display: '' }]);
    const [fk_usu_atual, setfk_usu_atual] = useState(0);
    const [comboTch, setComboTch] = useState([{ value: '', display: '' }]);
    let priOrdem = false;

    const columns = [
        { id: 'data_mov', hidden: true},
        { id: 'pk_cha', numeric: false, label: 'Nº Chamado', align: 'left', minWidth: 50 },
        { id: 'csi_cor', numeric: false, label: 'Situação', align: 'left', minWidth: 50, hidden: auth.fk_gpu > 2, order: 'nomesit' },
        { id: 'formatdata_abertura', numeric: false, label: 'Data Abertura', align: 'left', minWidth: 150, order: 'data_abertura' },
        { id: 'format_data_mov', numeric: false, label: 'Última Movimentação', align: 'left', minWidth: 150, order: 'data_mov' },
        { id: 'nomecli', numeric: false, label: 'Projeto', align: 'left', minWidth: 150 },
        { id: 'titulo', numeric: false, label: 'Título', align: 'left', minWidth: 150 },
        //{ id: 'nomemod', numeric: false, label: 'Módulo', align: 'left', minWidth: 150 },
        { id: 'nomeusu', numeric: false, label: 'Usuário Abertura', align: 'left', minWidth: 150 },
        { id: 'nomeusuatu', numeric: false, label: 'Usuário Atual', align: 'left', minWidth: 150, hidden: auth.fk_gpu > 2 },
        (auth.fk_gpu > 2 ? { id: 'nomesitex', numeric: false, label: 'Situação', align: 'left', minWidth: 150 } :
        { id: 'nomesit', numeric: false, label: 'Situação', align: 'left', minWidth: 150 }),
        { id: 'tempo_estimado', numeric: false, label: 'Tempo Estimado', align: 'left', minWidth: 150 },
        { id: 'prioridade', numeric: false, label: 'Prioridade', align: 'left', minWidth: 20, hidden: auth.fk_gpu > 2 },

    ]


    useEffect(() => {

        const fetchData = async () => {
            swal.fire({
                html: <FrLoading text="Carregando..." />,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            });

            setComboCli(await api.getComboData('clientes'));
            setComboUsu(await api.getComboData('usuarios'));
            setComboUsuAtual(await api.getComboData('usuarios'));
            setComboCsi(await api.getComboData('chamado_situacao'));
            await api.get('/usuarios/comboReq?interno=S').then(r => {
                setComboUsuResp(r.data);
                setComboUsuDev(r.data);
            });
            setComboTch(await api.getComboData('tipo_chamado'));



            const urlParams = new URLSearchParams(window.location.search);
            let query = paramsToObject(urlParams)
            setFilter(query);
            populateForm(document.getElementById('searchForm'), query);
            
            console.log(query);
            
            if (!window.location.search) query.first_acc = true;

            swal.close();
            if (1 == 1) {
                let event = new Event('filter');
                getData(event);
            }
        }

        fetchData();

    }, []);

    // useEffect(() => {
    //     console.log(data)
    // }, [data])


    useEffect(() => {
        const fetchData = async () => {
            setComboMod(await api.getComboData('modulos', filter.fk_cli * -1));
            setComboUsu(await api.getComboData('usuarios', filter.fk_cli))
            await api.get(`/usuarios/comboMov?fk_cli=${filter.fk_cli}`).then(r => {
                setComboUsuAtual(r.data)
            })
        }

        fetchData();
    }, [filter.fk_cli]);


    function getData(e) {
        e.preventDefault();
        var formData = new FormData(document.getElementById('searchForm'))
        var queryObject = formToObject(formData)
        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams);
        if (!window.location.search) queryObject.first_acc = 'true';
        // console.log(queryObject);
        // if (!isEmpty(queyObject)) {
        if (true) {
            if (query.buscar != 'true') queryObject.first_acc = 'true';
            queryObject.buscar = true;
            // queryObject.teste = true;      
            const auxValues = { ...filter };

            // console.log(auxValues)
            
            if (e.type === 'filter' && queryObject.first_acc == 'true') {
                console.log(queryObject)
                queryObject.resolvido = 'N';
                if (auth.fk_gpu <=2) {
                    auxValues.fk_usu_atual = fk_usu_atual
                    queryObject.fk_usu_atual = auth.user
                } else {
                    queryObject.fk_cli = auth.fk_cli
                    auxValues.fk_cli = auth.fk_cli
                    queryObject.publico = 'S'
                    auxValues.publico = 'S'
                    queryObject.fk_usu = auth.user
                    auxValues.fk_usu = auth.user
                }
            } else {

                // if (!auxValues.fk_csi) {
                //     auxValues.fk_csi = 1
                //     queryObject.fk_csi = 1
                // }


                if (auth.fk_gpu > 2) {
                    queryObject.fk_cli = auth.fk_cli
                    auxValues.fk_cli = auth.fk_cli
                    queryObject.publico = 'S'
                    auxValues.publico = 'S'
                } else
                    if (fk_usu_atual)
                        auxValues.fk_usu_atual = fk_usu_atual

                if (auth.fk_gpu == 4) {
                    queryObject.fk_usu = auth.user
                    auxValues.fk_usu = auth.user
                }
            }

            // setFilter(auxValues);

            setFiltered(true);

            window.history.replaceState({ filtered: true }, 'filter', "/suporte/chamados?" + objectToQueryString(queryObject));

            swal.fire({
                html: <FrLoading text="Buscando dados..." />,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            api.get('/chamados', { params: queryObject }).then(r => {
                console.log(r.data);
                setData(r.data);
                swal.close();
                queryObject.first_acc = false;
                auth.lastQuery = window.location.search;
                localStorage['authDelphus'] = JSON.stringify(auth);
                console.log(r.data)
                if(priOrdem == true){
                    priOrdem = false;
                    document.getElementById('prioridade').click();
                }
            })
        } else {
            swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning');
        }
    }



    function priorizar(e) {
        e.preventDefault();
        let prioridade;
        priOrdem = true;
        data.map((row, index) => {
            if (index == 0) {
                prioridade = row.prioridade
                // alert(prioridade)
                // alert(row.pk_cha)
            }
            if ((index > 0) && (row.prioridade !== 99)) {
                prioridade++
                api.get('/chamados/prioridade?pk_cha=' + row.pk_cha + '&prioridade=' + prioridade)
                // auxData.prioridade = prioridade
            }

            if (index + 1 == data.length) {
                let event = new Event('build');
                getData(event);

            }
        })
        // console.log(document) 
        // document.getElementById('prioridade').click();


    }

    const atualizar = (e) => {
        e.preventDefault();
        window.history.replaceState({ filtered: true }, 'filter','/suporte/chamados?first_acc=true');
        let event = new Event('filter');
        getData(event)
    }

    const clearForm = (e) => {
        e.preventDefault();
        window.history.replaceState({ filtered: false }, 'filter', '/suporte/chamados');
        document.getElementById('searchForm').reset()
        setFilter({})
    }

    const handleChange = (e) => {
        const auxValues = { ...filter };
        // console.log(auxValues);
        auxValues[e.target.name] = e.target.value;
        setFilter(auxValues);

        if (e.target.name == 'fk_usu_atual')
            setfk_usu_atual(e.target.value)
    }


    return (
        <div className="Manut">
            {auth.fk_gpu <= 2 ? <NavBar /> : <></>}

            <div className="ManutContent">
                <div className="defaultHeader">
                    <h3>Chamados</h3>
                    <UserOptions />
                </div>
                <br />
                <div className="filtersDiv">
                    <form id="searchForm" onSubmit={getData} >
                        <div>
                            <FrTextInput
                                maxLength='300'
                                value={filter.pk_cha || ''}
                                onChange={handleChange}
                                name="pk_cha"
                                id="pk_cha"
                                color="#9c9595"
                                label="Nº Chamado"
                                variant="outlined"
                                size="small"
                                style={{ width: 100 }}
                            />

                            <FrSelect
                                value={filter.fk_cli || ''}
                                onChange={handleChange}
                                name="fk_cli"
                                id="fk_cli"
                                label='Projeto'
                                data={comboCli}
                                style={{ width: 250 }}
                                disabled={auth.fk_gpu > 2}
                            />

                            <FrSelect
                                value={filter.fk_mod || ''}
                                onChange={handleChange}
                                name="fk_mod"
                                id="fk_mod"
                                label='Módulo do Sistema'
                                data={comboMod}
                                style={{ width: 250 }}
                            />

                            <FrSelect
                                value={filter.fk_tch || ''}
                                onChange={handleChange}
                                name="fk_tch"
                                id="fk_tch"
                                label='Tipo de Chamado'
                                data={comboTch}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 250 }}
                                hidden={auth.fk_gpu > 2}
                            />

                            <FrDatePicker
                                value={(filter.dataini_emissao || '').split('T')[0]}
                                name="dataini_emissao"
                                id="dataini_emissao"
                                color="#528b46"
                                label="Período de Abertura"
                                variant="outlined"
                                size="small"
                                style={{ width: 220 }}
                                onChange={handleChange}
                            />

                            <label hidden={isMobile} style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                            <FrDatePicker
                                value={(filter.datafim_emissao || '').split('T')[0]}
                                name="datafim_emissao"
                                id="datafim_emissao"
                                color="#528b46"
                                variant="outlined"
                                size="small"
                                style={{ width: 220 }}
                                onChange={handleChange}
                            />
                            <br />
                            <FrSelect
                                value={filter.fk_usu || ''}
                                onChange={handleChange}
                                name="fk_usu"
                                id="fk_usu"
                                label='Solicitante'
                                data={comboUsu}
                                style={{ width: 250 }}
                                disabled={(auth.fk_gpu == 4)}
                            />

                            <FrDatePicker
                                value={(filter.dataini_encerra || '').split('T')[0]}
                                name="dataini_encerra"
                                id="dataini_encerra"
                                color="#528b46"
                                label="Período de Encerramento"
                                variant="outlined"
                                size="small"
                                style={{ width: 220 }}
                                onChange={handleChange}
                            />

                            <label hidden={isMobile} style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                            <FrDatePicker
                                value={(filter.datafim_encerramento || '').split('T')[0]}
                                name="datafim_encerramento"
                                id="datafim_encerramento"
                                color="#528b46"
                                variant="outlined"
                                size="small"
                                style={{ width: 220 }}
                                onChange={handleChange}
                            />
                            <FrSelect
                                value={filter.fk_csi || ''}
                                onChange={handleChange}
                                name="fk_csi"
                                id="fk_csi"
                                label='Situação'
                                data={comboCsi}
                                style={{ width: 200 }}
                            />
                            <FrSelect
                                value={filter.fk_usu_atual || ''}
                                onChange={handleChange}
                                name="fk_usu_atual"
                                id="fk_usu_atual"
                                label='Usuário Atual'
                                data={comboUsuAtual}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 250 }}
                                hidden={auth.fk_gpu > 2}
                            />
                            <FrSelect
                                value={filter.fk_usu_dev || ''}
                                onChange={handleChange}
                                name="fk_usu_dev"
                                id="fk_usu_dev"
                                label='Usuário Liberador'
                                data={comboUsuDev}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 250 }}
                                hidden={auth.fk_gpu > 2}
                            />
                            <FrSelect
                                value={filter.fk_usu_responsavel || ''}
                                onChange={handleChange}
                                name="fk_usu_responsavel"
                                id="fk_usu_responsavel"
                                label='Responsável'
                                data={comboUsuResp}
                                style={{ width: auth.fk_gpu > 2 ? 0 : 255 }}
                                hidden={auth.fk_gpu > 2}
                            />
                            <FrSelect
                                value={filter.resolvido || ''}
                                onChange={handleChange}
                                name="resolvido"
                                id="resolvido"
                                label='Resolvido'
                                data={[{display: 'Sim', value: 'S'}, {display: 'Não', value: 'N'}]}
                                style={{ width: 200, marginLeft: auth.fk_gpu > 2 ? -6 : 0 }}
                            />
                            <br/>
                            <FrDatePicker
                                value={(filter.data_liberacaoini || '').split('T')[0]}
                                name="data_liberacaoini"
                                id="data_liberacaoini"
                                label="Data de Liberação"
                                color="#528b46"
                                variant="outlined"
                                size="small"
                                style={{ width: 220 }}
                                onChange={handleChange}
                            />

                            <label hidden={isMobile} style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                            <FrDatePicker
                                value={(filter.data_liberacaofim || '').split('T')[0]}
                                name="data_liberacaofim"
                                id="data_liberacaofim"
                                color="#528b46"
                                variant="outlined"
                                size="small"
                                style={{ width: 220 }}
                                onChange={handleChange}
                            />
                            <div>
                                <button onClick={getData}>
                                    <Icon icon={ic_search} size={18} />
                                    Pesquisar
                                </button>
                                <button onClick={clearForm}>
                                    <Icon icon={ic_delete} size={18} />
                                    Limpar
                                </button>
                                <Link to="/suporte/chamados/registro?manut=S">
                                    <button >
                                        <Icon icon={ic_add_box} size={18} />
                                        Novo Registro
                                    </button>
                                </Link>
                                <button hidden={auth.fk_gpu > 2} disabled={auth.fk_gpu != 1} onClick={priorizar} style={auth.fk_gpu != 1 ? { backgroundColor: '#c7bebd'} : {}}>
                                    <Icon icon={ic_sync} size={18} />
                                    Priorizar
                                </button>
                                <button hidden={auth.fk_gpu > 2} onClick={atualizar}>
                                    <Icon icon={spinner11} size={14} />
                                    Atualizar
                                </button>

                            </div>
                        </div>
                    </form>

                </div>
                <br />
                <div className="tableDiv">
                <FrTable
                        detail={true}
                        orderBy={'pk_cha'}
                        order={'desc'}
                        getData={getData}
                        regName="chamados"
                        columns={columns}
                        searched={filtered}
                        codeName="pk_cha"
                        page={'chamados'}
                        data={data}
                        setData={setData}
                        enableEdit={'S'}
                        enableDel={'N'}
                        rowsPerPage={100}
                    />
                </div>
                <FooterDelphus />
            </div>
        </div >
    );
}

export default Chamados;
